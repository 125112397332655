const interfaceTranslations = {
  selectedCountryAriaLabel: "Выбранная страна",
  noCountrySelected: "Страна не выбрана",
  countryListAriaLabel: "Список стран",
  searchPlaceholder: "Поиск",
  zeroSearchResults: "результатов не найдено",
  oneSearchResult: "найден 1 результат",
  multipleSearchResults: "Найдено ${count} результатов",
  // additional countries (not supported by country-list library)
  ac: "Остров Вознесения",
  xk: "Косово"
};
export default interfaceTranslations;
