const moveToHeader = () => {
  const buttonUp = document.querySelector('.js-up');
  const body = document.querySelector('body');

  if (!buttonUp) {
    return;
  }

  // eslint-disable-next-line no-undef
  const moveTo = new MoveTo({
    duration: 1500,
    easing: 'easeOutQuart'
  });

  buttonUp.addEventListener('click', (evt) => {
    evt.preventDefault();
    moveTo.move(body);
  });

  window.addEventListener('scroll', () => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    scrollY > document.documentElement.clientHeight ? buttonUp.classList.add('footer__up-btn--visible') : buttonUp.classList.remove('footer__up-btn--visible');
  });
};

export default moveToHeader;
