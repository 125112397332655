const countryTranslations = {
  ad: "Andorra",
  ae: "Emirats Àrabs Units",
  af: "Afganistan",
  ag: "Antigua i Barbuda",
  ai: "Anguilla",
  al: "Albània",
  am: "Armènia",
  ao: "Angola",
  ar: "Argentina",
  as: "Samoa Nord-americana",
  at: "Àustria",
  au: "Austràlia",
  aw: "Aruba",
  ax: "Illes Åland",
  az: "Azerbaidjan",
  ba: "Bòsnia i Hercegovina",
  bb: "Barbados",
  bd: "Bangladesh",
  be: "Bèlgica",
  bf: "Burkina Faso",
  bg: "Bulgària",
  bh: "Bahrain",
  bi: "Burundi",
  bj: "Benín",
  bl: "Saint Barthélemy",
  bm: "Bermudes",
  bn: "Brunei",
  bo: "Bolívia",
  bq: "Carib Neerlandès",
  br: "Brasil",
  bs: "Bahames",
  bt: "Bhutan",
  bw: "Botswana",
  by: "Belarús",
  bz: "Belize",
  ca: "Canadà",
  cc: "Illes Cocos",
  cd: "Congo - Kinshasa",
  cf: "República Centreafricana",
  cg: "Congo - Brazzaville",
  ch: "Suïssa",
  ci: "Côte d’Ivoire",
  ck: "Illes Cook",
  cl: "Xile",
  cm: "Camerun",
  cn: "Xina",
  co: "Colòmbia",
  cr: "Costa Rica",
  cu: "Cuba",
  cv: "Cap Verd",
  cw: "Curaçao",
  cx: "Illa Christmas",
  cy: "Xipre",
  cz: "Txèquia",
  de: "Alemanya",
  dj: "Djibouti",
  dk: "Dinamarca",
  dm: "Dominica",
  do: "República Dominicana",
  dz: "Algèria",
  ec: "Equador",
  ee: "Estònia",
  eg: "Egipte",
  eh: "Sàhara Occidental",
  er: "Eritrea",
  es: "Espanya",
  et: "Etiòpia",
  fi: "Finlàndia",
  fj: "Fiji",
  fk: "Illes Malvines",
  fm: "Micronèsia",
  fo: "Illes Fèroe",
  fr: "França",
  ga: "Gabon",
  gb: "Regne Unit",
  gd: "Grenada",
  ge: "Geòrgia",
  gf: "Guaiana Francesa",
  gg: "Guernsey",
  gh: "Ghana",
  gi: "Gibraltar",
  gl: "Groenlàndia",
  gm: "Gàmbia",
  gn: "Guinea",
  gp: "Guadeloupe",
  gq: "Guinea Equatorial",
  gr: "Grècia",
  gt: "Guatemala",
  gu: "Guam",
  gw: "Guinea Bissau",
  gy: "Guyana",
  hk: "Hong Kong (RAE Xina)",
  hn: "Hondures",
  hr: "Croàcia",
  ht: "Haití",
  hu: "Hongria",
  id: "Indonèsia",
  ie: "Irlanda",
  il: "Israel",
  im: "Illa de Man",
  in: "Índia",
  io: "Territori Britànic de l’Oceà Índic",
  iq: "Iraq",
  ir: "Iran",
  is: "Islàndia",
  it: "Itàlia",
  je: "Jersey",
  jm: "Jamaica",
  jo: "Jordània",
  jp: "Japó",
  ke: "Kenya",
  kg: "Kirguizistan",
  kh: "Cambodja",
  ki: "Kiribati",
  km: "Comores",
  kn: "Saint Christopher i Nevis",
  kp: "Corea del Nord",
  kr: "Corea del Sud",
  kw: "Kuwait",
  ky: "Illes Caiman",
  kz: "Kazakhstan",
  la: "Laos",
  lb: "Líban",
  lc: "Saint Lucia",
  li: "Liechtenstein",
  lk: "Sri Lanka",
  lr: "Libèria",
  ls: "Lesotho",
  lt: "Lituània",
  lu: "Luxemburg",
  lv: "Letònia",
  ly: "Líbia",
  ma: "Marroc",
  mc: "Mònaco",
  md: "Moldàvia",
  me: "Montenegro",
  mf: "Saint Martin",
  mg: "Madagascar",
  mh: "Illes Marshall",
  mk: "Macedònia del Nord",
  ml: "Mali",
  mm: "Myanmar (Birmània)",
  mn: "Mongòlia",
  mo: "Macau (RAE Xina)",
  mp: "Illes Mariannes del Nord",
  mq: "Martinica",
  mr: "Mauritània",
  ms: "Montserrat",
  mt: "Malta",
  mu: "Maurici",
  mv: "Maldives",
  mw: "Malawi",
  mx: "Mèxic",
  my: "Malàisia",
  mz: "Moçambic",
  na: "Namíbia",
  nc: "Nova Caledònia",
  ne: "Níger",
  nf: "Norfolk",
  ng: "Nigèria",
  ni: "Nicaragua",
  nl: "Països Baixos",
  no: "Noruega",
  np: "Nepal",
  nr: "Nauru",
  nu: "Niue",
  nz: "Nova Zelanda",
  om: "Oman",
  pa: "Panamà",
  pe: "Perú",
  pf: "Polinèsia Francesa",
  pg: "Papua Nova Guinea",
  ph: "Filipines",
  pk: "Pakistan",
  pl: "Polònia",
  pm: "Saint-Pierre-et-Miquelon",
  pr: "Puerto Rico",
  ps: "Territoris palestins",
  pt: "Portugal",
  pw: "Palau",
  py: "Paraguai",
  qa: "Qatar",
  re: "Illa de la Reunió",
  ro: "Romania",
  rs: "Sèrbia",
  ru: "Rússia",
  rw: "Ruanda",
  sa: "Aràbia Saudita",
  sb: "Illes Salomó",
  sc: "Seychelles",
  sd: "Sudan",
  se: "Suècia",
  sg: "Singapur",
  sh: "Saint Helena",
  si: "Eslovènia",
  sj: "Svalbard i Jan Mayen",
  sk: "Eslovàquia",
  sl: "Sierra Leone",
  sm: "San Marino",
  sn: "Senegal",
  so: "Somàlia",
  sr: "Surinam",
  ss: "Sudan del Sud",
  st: "São Tomé i Príncipe",
  sv: "El Salvador",
  sx: "Sint Maarten",
  sy: "Síria",
  sz: "eSwatini",
  tc: "Illes Turks i Caicos",
  td: "Txad",
  tg: "Togo",
  th: "Tailàndia",
  tj: "Tadjikistan",
  tk: "Tokelau",
  tl: "Timor Oriental",
  tm: "Turkmenistan",
  tn: "Tunísia",
  to: "Tonga",
  tr: "Turquia",
  tt: "Trinitat i Tobago",
  tv: "Tuvalu",
  tw: "Taiwan",
  tz: "Tanzània",
  ua: "Ucraïna",
  ug: "Uganda",
  us: "Estats Units",
  uy: "Uruguai",
  uz: "Uzbekistan",
  va: "Ciutat del Vaticà",
  vc: "Saint Vincent i les Grenadines",
  ve: "Veneçuela",
  vg: "Illes Verges Britàniques",
  vi: "Illes Verges Nord-americanes",
  vn: "Vietnam",
  vu: "Vanuatu",
  wf: "Wallis i Futuna",
  ws: "Samoa",
  ye: "Iemen",
  yt: "Mayotte",
  za: "República de Sud-àfrica",
  zm: "Zàmbia",
  zw: "Zimbàbue"
};
export default countryTranslations;
