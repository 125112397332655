const countryTranslations = {
  ad: "安道尔",
  ae: "阿拉伯联合酋长国",
  af: "阿富汗",
  ag: "安提瓜和巴布达",
  ai: "安圭拉",
  al: "阿尔巴尼亚",
  am: "亚美尼亚",
  ao: "安哥拉",
  ar: "阿根廷",
  as: "美属萨摩亚",
  at: "奥地利",
  au: "澳大利亚",
  aw: "阿鲁巴",
  ax: "奥兰群岛",
  az: "阿塞拜疆",
  ba: "波斯尼亚和黑塞哥维那",
  bb: "巴巴多斯",
  bd: "孟加拉国",
  be: "比利时",
  bf: "布基纳法索",
  bg: "保加利亚",
  bh: "巴林",
  bi: "布隆迪",
  bj: "贝宁",
  bl: "圣巴泰勒米",
  bm: "百慕大",
  bn: "文莱",
  bo: "玻利维亚",
  bq: "荷属加勒比区",
  br: "巴西",
  bs: "巴哈马",
  bt: "不丹",
  bw: "博茨瓦纳",
  by: "白俄罗斯",
  bz: "伯利兹",
  ca: "加拿大",
  cc: "科科斯（基林）群岛",
  cd: "刚果（金）",
  cf: "中非共和国",
  cg: "刚果（布）",
  ch: "瑞士",
  ci: "科特迪瓦",
  ck: "库克群岛",
  cl: "智利",
  cm: "喀麦隆",
  cn: "中国",
  co: "哥伦比亚",
  cr: "哥斯达黎加",
  cu: "古巴",
  cv: "佛得角",
  cw: "库拉索",
  cx: "圣诞岛",
  cy: "塞浦路斯",
  cz: "捷克",
  de: "德国",
  dj: "吉布提",
  dk: "丹麦",
  dm: "多米尼克",
  do: "多米尼加共和国",
  dz: "阿尔及利亚",
  ec: "厄瓜多尔",
  ee: "爱沙尼亚",
  eg: "埃及",
  eh: "西撒哈拉",
  er: "厄立特里亚",
  es: "西班牙",
  et: "埃塞俄比亚",
  fi: "芬兰",
  fj: "斐济",
  fk: "福克兰群岛",
  fm: "密克罗尼西亚",
  fo: "法罗群岛",
  fr: "法国",
  ga: "加蓬",
  gb: "英国",
  gd: "格林纳达",
  ge: "格鲁吉亚",
  gf: "法属圭亚那",
  gg: "根西岛",
  gh: "加纳",
  gi: "直布罗陀",
  gl: "格陵兰",
  gm: "冈比亚",
  gn: "几内亚",
  gp: "瓜德罗普",
  gq: "赤道几内亚",
  gr: "希腊",
  gt: "危地马拉",
  gu: "关岛",
  gw: "几内亚比绍",
  gy: "圭亚那",
  hk: "中国香港特别行政区",
  hn: "洪都拉斯",
  hr: "克罗地亚",
  ht: "海地",
  hu: "匈牙利",
  id: "印度尼西亚",
  ie: "爱尔兰",
  il: "以色列",
  im: "马恩岛",
  in: "印度",
  io: "英属印度洋领地",
  iq: "伊拉克",
  ir: "伊朗",
  is: "冰岛",
  it: "意大利",
  je: "泽西岛",
  jm: "牙买加",
  jo: "约旦",
  jp: "日本",
  ke: "肯尼亚",
  kg: "吉尔吉斯斯坦",
  kh: "柬埔寨",
  ki: "基里巴斯",
  km: "科摩罗",
  kn: "圣基茨和尼维斯",
  kp: "朝鲜",
  kr: "韩国",
  kw: "科威特",
  ky: "开曼群岛",
  kz: "哈萨克斯坦",
  la: "老挝",
  lb: "黎巴嫩",
  lc: "圣卢西亚",
  li: "列支敦士登",
  lk: "斯里兰卡",
  lr: "利比里亚",
  ls: "莱索托",
  lt: "立陶宛",
  lu: "卢森堡",
  lv: "拉脱维亚",
  ly: "利比亚",
  ma: "摩洛哥",
  mc: "摩纳哥",
  md: "摩尔多瓦",
  me: "黑山",
  mf: "法属圣马丁",
  mg: "马达加斯加",
  mh: "马绍尔群岛",
  mk: "北马其顿",
  ml: "马里",
  mm: "缅甸",
  mn: "蒙古",
  mo: "中国澳门特别行政区",
  mp: "北马里亚纳群岛",
  mq: "马提尼克",
  mr: "毛里塔尼亚",
  ms: "蒙特塞拉特",
  mt: "马耳他",
  mu: "毛里求斯",
  mv: "马尔代夫",
  mw: "马拉维",
  mx: "墨西哥",
  my: "马来西亚",
  mz: "莫桑比克",
  na: "纳米比亚",
  nc: "新喀里多尼亚",
  ne: "尼日尔",
  nf: "诺福克岛",
  ng: "尼日利亚",
  ni: "尼加拉瓜",
  nl: "荷兰",
  no: "挪威",
  np: "尼泊尔",
  nr: "瑙鲁",
  nu: "纽埃",
  nz: "新西兰",
  om: "阿曼",
  pa: "巴拿马",
  pe: "秘鲁",
  pf: "法属波利尼西亚",
  pg: "巴布亚新几内亚",
  ph: "菲律宾",
  pk: "巴基斯坦",
  pl: "波兰",
  pm: "圣皮埃尔和密克隆群岛",
  pr: "波多黎各",
  ps: "巴勒斯坦领土",
  pt: "葡萄牙",
  pw: "帕劳",
  py: "巴拉圭",
  qa: "卡塔尔",
  re: "留尼汪",
  ro: "罗马尼亚",
  rs: "塞尔维亚",
  ru: "俄罗斯",
  rw: "卢旺达",
  sa: "沙特阿拉伯",
  sb: "所罗门群岛",
  sc: "塞舌尔",
  sd: "苏丹",
  se: "瑞典",
  sg: "新加坡",
  sh: "圣赫勒拿",
  si: "斯洛文尼亚",
  sj: "斯瓦尔巴和扬马延",
  sk: "斯洛伐克",
  sl: "塞拉利昂",
  sm: "圣马力诺",
  sn: "塞内加尔",
  so: "索马里",
  sr: "苏里南",
  ss: "南苏丹",
  st: "圣多美和普林西比",
  sv: "萨尔瓦多",
  sx: "荷属圣马丁",
  sy: "叙利亚",
  sz: "斯威士兰",
  tc: "特克斯和凯科斯群岛",
  td: "乍得",
  tg: "多哥",
  th: "泰国",
  tj: "塔吉克斯坦",
  tk: "托克劳",
  tl: "东帝汶",
  tm: "土库曼斯坦",
  tn: "突尼斯",
  to: "汤加",
  tr: "土耳其",
  tt: "特立尼达和多巴哥",
  tv: "图瓦卢",
  tw: "台湾",
  tz: "坦桑尼亚",
  ua: "乌克兰",
  ug: "乌干达",
  us: "美国",
  uy: "乌拉圭",
  uz: "乌兹别克斯坦",
  va: "梵蒂冈",
  vc: "圣文森特和格林纳丁斯",
  ve: "委内瑞拉",
  vg: "英属维尔京群岛",
  vi: "美属维尔京群岛",
  vn: "越南",
  vu: "瓦努阿图",
  wf: "瓦利斯和富图纳",
  ws: "萨摩亚",
  ye: "也门",
  yt: "马约特",
  za: "南非",
  zm: "赞比亚",
  zw: "津巴布韦"
};
export default countryTranslations;
