const countryTranslations = {
  ad: "อันดอร์รา",
  ae: "สหรัฐอาหรับเอมิเรตส์",
  af: "อัฟกานิสถาน",
  ag: "แอนติกาและบาร์บูดา",
  ai: "แองกวิลลา",
  al: "แอลเบเนีย",
  am: "อาร์เมเนีย",
  ao: "แองโกลา",
  ar: "อาร์เจนตินา",
  as: "อเมริกันซามัว",
  at: "ออสเตรีย",
  au: "ออสเตรเลีย",
  aw: "อารูบา",
  ax: "หมู่เกาะโอลันด์",
  az: "อาเซอร์ไบจาน",
  ba: "บอสเนียและเฮอร์เซโกวีนา",
  bb: "บาร์เบโดส",
  bd: "บังกลาเทศ",
  be: "เบลเยียม",
  bf: "บูร์กินาฟาโซ",
  bg: "บัลแกเรีย",
  bh: "บาห์เรน",
  bi: "บุรุนดี",
  bj: "เบนิน",
  bl: "เซนต์บาร์เธเลมี",
  bm: "เบอร์มิวดา",
  bn: "บรูไน",
  bo: "โบลิเวีย",
  bq: "เนเธอร์แลนด์แคริบเบียน",
  br: "บราซิล",
  bs: "บาฮามาส",
  bt: "ภูฏาน",
  bw: "บอตสวานา",
  by: "เบลารุส",
  bz: "เบลีซ",
  ca: "แคนาดา",
  cc: "หมู่เกาะโคโคส (คีลิง)",
  cd: "คองโก - กินชาซา",
  cf: "สาธารณรัฐแอฟริกากลาง",
  cg: "คองโก - บราซซาวิล",
  ch: "สวิตเซอร์แลนด์",
  ci: "โกตดิวัวร์",
  ck: "หมู่เกาะคุก",
  cl: "ชิลี",
  cm: "แคเมอรูน",
  cn: "จีน",
  co: "โคลอมเบีย",
  cr: "คอสตาริกา",
  cu: "คิวบา",
  cv: "เคปเวิร์ด",
  cw: "คูราเซา",
  cx: "เกาะคริสต์มาส",
  cy: "ไซปรัส",
  cz: "เช็ก",
  de: "เยอรมนี",
  dj: "จิบูตี",
  dk: "เดนมาร์ก",
  dm: "โดมินิกา",
  do: "สาธารณรัฐโดมินิกัน",
  dz: "แอลจีเรีย",
  ec: "เอกวาดอร์",
  ee: "เอสโตเนีย",
  eg: "อียิปต์",
  eh: "ซาฮาราตะวันตก",
  er: "เอริเทรีย",
  es: "สเปน",
  et: "เอธิโอเปีย",
  fi: "ฟินแลนด์",
  fj: "ฟิจิ",
  fk: "หมู่เกาะฟอล์กแลนด์",
  fm: "ไมโครนีเซีย",
  fo: "หมู่เกาะแฟโร",
  fr: "ฝรั่งเศส",
  ga: "กาบอง",
  gb: "สหราชอาณาจักร",
  gd: "เกรเนดา",
  ge: "จอร์เจีย",
  gf: "เฟรนช์เกียนา",
  gg: "เกิร์นซีย์",
  gh: "กานา",
  gi: "ยิบรอลตาร์",
  gl: "กรีนแลนด์",
  gm: "แกมเบีย",
  gn: "กินี",
  gp: "กวาเดอลูป",
  gq: "อิเควทอเรียลกินี",
  gr: "กรีซ",
  gt: "กัวเตมาลา",
  gu: "กวม",
  gw: "กินี-บิสเซา",
  gy: "กายอานา",
  hk: "เขตปกครองพิเศษฮ่องกงแห่งสาธารณรัฐประชาชนจีน",
  hn: "ฮอนดูรัส",
  hr: "โครเอเชีย",
  ht: "เฮติ",
  hu: "ฮังการี",
  id: "อินโดนีเซีย",
  ie: "ไอร์แลนด์",
  il: "อิสราเอล",
  im: "เกาะแมน",
  in: "อินเดีย",
  io: "บริติชอินเดียนโอเชียนเทร์ริทอรี",
  iq: "อิรัก",
  ir: "อิหร่าน",
  is: "ไอซ์แลนด์",
  it: "อิตาลี",
  je: "เจอร์ซีย์",
  jm: "จาเมกา",
  jo: "จอร์แดน",
  jp: "ญี่ปุ่น",
  ke: "เคนยา",
  kg: "คีร์กีซสถาน",
  kh: "กัมพูชา",
  ki: "คิริบาส",
  km: "คอโมโรส",
  kn: "เซนต์คิตส์และเนวิส",
  kp: "เกาหลีเหนือ",
  kr: "เกาหลีใต้",
  kw: "คูเวต",
  ky: "หมู่เกาะเคย์แมน",
  kz: "คาซัคสถาน",
  la: "ลาว",
  lb: "เลบานอน",
  lc: "เซนต์ลูเซีย",
  li: "ลิกเตนสไตน์",
  lk: "ศรีลังกา",
  lr: "ไลบีเรีย",
  ls: "เลโซโท",
  lt: "ลิทัวเนีย",
  lu: "ลักเซมเบิร์ก",
  lv: "ลัตเวีย",
  ly: "ลิเบีย",
  ma: "โมร็อกโก",
  mc: "โมนาโก",
  md: "มอลโดวา",
  me: "มอนเตเนโกร",
  mf: "เซนต์มาร์ติน",
  mg: "มาดากัสการ์",
  mh: "หมู่เกาะมาร์แชลล์",
  mk: "มาซิโดเนียเหนือ",
  ml: "มาลี",
  mm: "เมียนมาร์ (พม่า)",
  mn: "มองโกเลีย",
  mo: "เขตปกครองพิเศษมาเก๊าแห่งสาธารณรัฐประชาชนจีน",
  mp: "หมู่เกาะนอร์เทิร์นมาเรียนา",
  mq: "มาร์ตินีก",
  mr: "มอริเตเนีย",
  ms: "มอนต์เซอร์รัต",
  mt: "มอลตา",
  mu: "มอริเชียส",
  mv: "มัลดีฟส์",
  mw: "มาลาวี",
  mx: "เม็กซิโก",
  my: "มาเลเซีย",
  mz: "โมซัมบิก",
  na: "นามิเบีย",
  nc: "นิวแคลิโดเนีย",
  ne: "ไนเจอร์",
  nf: "เกาะนอร์ฟอล์ก",
  ng: "ไนจีเรีย",
  ni: "นิการากัว",
  nl: "เนเธอร์แลนด์",
  no: "นอร์เวย์",
  np: "เนปาล",
  nr: "นาอูรู",
  nu: "นีอูเอ",
  nz: "นิวซีแลนด์",
  om: "โอมาน",
  pa: "ปานามา",
  pe: "เปรู",
  pf: "เฟรนช์โปลินีเซีย",
  pg: "ปาปัวนิวกินี",
  ph: "ฟิลิปปินส์",
  pk: "ปากีสถาน",
  pl: "โปแลนด์",
  pm: "แซงปีแยร์และมีเกอลง",
  pr: "เปอร์โตริโก",
  ps: "ดินแดนปาเลสไตน์",
  pt: "โปรตุเกส",
  pw: "ปาเลา",
  py: "ปารากวัย",
  qa: "กาตาร์",
  re: "เรอูนียง",
  ro: "โรมาเนีย",
  rs: "เซอร์เบีย",
  ru: "รัสเซีย",
  rw: "รวันดา",
  sa: "ซาอุดีอาระเบีย",
  sb: "หมู่เกาะโซโลมอน",
  sc: "เซเชลส์",
  sd: "ซูดาน",
  se: "สวีเดน",
  sg: "สิงคโปร์",
  sh: "เซนต์เฮเลนา",
  si: "สโลวีเนีย",
  sj: "สฟาลบาร์และยานไมเอน",
  sk: "สโลวะเกีย",
  sl: "เซียร์ราลีโอน",
  sm: "ซานมาริโน",
  sn: "เซเนกัล",
  so: "โซมาเลีย",
  sr: "ซูรินาเม",
  ss: "ซูดานใต้",
  st: "เซาตูเมและปรินซิปี",
  sv: "เอลซัลวาดอร์",
  sx: "ซินต์มาร์เทน",
  sy: "ซีเรีย",
  sz: "เอสวาตีนี",
  tc: "หมู่เกาะเติกส์และหมู่เกาะเคคอส",
  td: "ชาด",
  tg: "โตโก",
  th: "ไทย",
  tj: "ทาจิกิสถาน",
  tk: "โตเกเลา",
  tl: "ติมอร์-เลสเต",
  tm: "เติร์กเมนิสถาน",
  tn: "ตูนิเซีย",
  to: "ตองกา",
  tr: "ตุรกี",
  tt: "ตรินิแดดและโตเบโก",
  tv: "ตูวาลู",
  tw: "ไต้หวัน",
  tz: "แทนซาเนีย",
  ua: "ยูเครน",
  ug: "ยูกันดา",
  us: "สหรัฐอเมริกา",
  uy: "อุรุกวัย",
  uz: "อุซเบกิสถาน",
  va: "นครวาติกัน",
  vc: "เซนต์วินเซนต์และเกรนาดีนส์",
  ve: "เวเนซุเอลา",
  vg: "หมู่เกาะบริติชเวอร์จิน",
  vi: "หมู่เกาะเวอร์จินของสหรัฐอเมริกา",
  vn: "เวียดนาม",
  vu: "วานูอาตู",
  wf: "วาลลิสและฟุตูนา",
  ws: "ซามัว",
  ye: "เยเมน",
  yt: "มายอต",
  za: "แอฟริกาใต้",
  zm: "แซมเบีย",
  zw: "ซิมบับเว"
};
export default countryTranslations;
