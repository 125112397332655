const createVKIframe = (videoId) => {
  const iframe = document.createElement('iframe'),
    videoParams = videoId.replace('video', '').split('_');

  iframe.setAttribute('src', `https://vkvideo.ru/video_ext.php?oid=${videoParams[0]}&id=${videoParams[1]}&hd=2&autoplay=1`);
  iframe.setAttribute('allowfullscreen', '');
  iframe.setAttribute('frameborder', '0');
  iframe.setAttribute('allow', 'autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;');

  return iframe;
};

const validateVideoId = (videoId) => {
  return new RegExp(/video(-?[0-9_]+)/).test(videoId);
};

const renderBackground = (videoData) => {
  document.querySelectorAll('.vk-video').forEach((player) => {
    const videoId = player.dataset.vk;
    if (!videoId || !validateVideoId(videoId) || !videoData[videoId]) {
      return;
    }

    const videoInfo = videoData[videoId];

    player.style.backgroundImage = `url(${videoInfo.image})`;

    if (videoInfo.isVertical)
      player.classList.add('vk-video--vertical');

  });
};

const initVkPlayer = () => {
  const players = document.querySelectorAll('.vk-video'),
    videosId = [];
  players.forEach((player) => {
    const videoId = player.dataset.vk;
    if (!videoId || !validateVideoId(videoId)) {
      return;
    }

    videosId.push(videoId);

    player.addEventListener('click', () => {
      player.style.backgroundImage = null;
      const iframe = createVKIframe(videoId);
      player.appendChild(iframe);
    });
  });

  if (videosId.length) {
    fetch('/local/ajax/vk-video', {
      method: 'POST',
      headers: {
        'Bx-ajax': true,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        videos: videosId
      })
    }).then(response => response.json())
      .then(data => renderBackground(data));
  }
};

export {initVkPlayer};
