const interfaceTranslations = {
  selectedCountryAriaLabel: "निवडलेला देश",
  noCountrySelected: "कोणताही देश निवडलेला नाही",
  countryListAriaLabel: "देशांची यादी",
  searchPlaceholder: "शोधा",
  zeroSearchResults: "कोणतेही परिणाम आढळले नाहीत",
  oneSearchResult: "1 परिणाम आढळला",
  multipleSearchResults: "${count} परिणाम आढळले",
  // additional countries (not supported by country-list library)
  ac: "असेन्शन बेट",
  xk: "कोसोवो"
};
export default interfaceTranslations;
