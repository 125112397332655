const interfaceTranslations = {
  selectedCountryAriaLabel: "البلد المحدد",
  noCountrySelected: "لم يتم تحديد أي بلد",
  countryListAriaLabel: "قائمة الدول",
  searchPlaceholder: "يبحث",
  zeroSearchResults: "لم يتم العثور على نتائج",
  oneSearchResult: "تم العثور على نتيجة واحدة",
  multipleSearchResults: "تم العثور على ${count} من النتائج",
  // additional countries (not supported by country-list library)
  ac: "جزيرة الصعود",
  xk: "كوسوفو"
};
export default interfaceTranslations;
