const countryTranslations = {
  ad: "Ανδόρα",
  ae: "Ηνωμένα Αραβικά Εμιράτα",
  af: "Αφγανιστάν",
  ag: "Αντίγκουα και Μπαρμπούντα",
  ai: "Ανγκουίλα",
  al: "Αλβανία",
  am: "Αρμενία",
  ao: "Αγκόλα",
  ar: "Αργεντινή",
  as: "Αμερικανική Σαμόα",
  at: "Αυστρία",
  au: "Αυστραλία",
  aw: "Αρούμπα",
  ax: "Νήσοι Όλαντ",
  az: "Αζερμπαϊτζάν",
  ba: "Βοσνία - Ερζεγοβίνη",
  bb: "Μπαρμπέιντος",
  bd: "Μπανγκλαντές",
  be: "Βέλγιο",
  bf: "Μπουρκίνα Φάσο",
  bg: "Βουλγαρία",
  bh: "Μπαχρέιν",
  bi: "Μπουρούντι",
  bj: "Μπενίν",
  bl: "Άγιος Βαρθολομαίος",
  bm: "Βερμούδες",
  bn: "Μπρουνέι",
  bo: "Βολιβία",
  bq: "Ολλανδία Καραϊβικής",
  br: "Βραζιλία",
  bs: "Μπαχάμες",
  bt: "Μπουτάν",
  bw: "Μποτσουάνα",
  by: "Λευκορωσία",
  bz: "Μπελίζ",
  ca: "Καναδάς",
  cc: "Νήσοι Κόκος (Κίλινγκ)",
  cd: "Κονγκό - Κινσάσα",
  cf: "Κεντροαφρικανική Δημοκρατία",
  cg: "Κονγκό - Μπραζαβίλ",
  ch: "Ελβετία",
  ci: "Ακτή Ελεφαντοστού",
  ck: "Νήσοι Κουκ",
  cl: "Χιλή",
  cm: "Καμερούν",
  cn: "Κίνα",
  co: "Κολομβία",
  cr: "Κόστα Ρίκα",
  cu: "Κούβα",
  cv: "Πράσινο Ακρωτήριο",
  cw: "Κουρασάο",
  cx: "Νήσος των Χριστουγέννων",
  cy: "Κύπρος",
  cz: "Τσεχία",
  de: "Γερμανία",
  dj: "Τζιμπουτί",
  dk: "Δανία",
  dm: "Ντομίνικα",
  do: "Δομινικανή Δημοκρατία",
  dz: "Αλγερία",
  ec: "Ισημερινός",
  ee: "Εσθονία",
  eg: "Αίγυπτος",
  eh: "Δυτική Σαχάρα",
  er: "Ερυθραία",
  es: "Ισπανία",
  et: "Αιθιοπία",
  fi: "Φινλανδία",
  fj: "Φίτζι",
  fk: "Νήσοι Φόκλαντ",
  fm: "Μικρονησία",
  fo: "Νήσοι Φερόες",
  fr: "Γαλλία",
  ga: "Γκαμπόν",
  gb: "Ηνωμένο Βασίλειο",
  gd: "Γρενάδα",
  ge: "Γεωργία",
  gf: "Γαλλική Γουιάνα",
  gg: "Γκέρνζι",
  gh: "Γκάνα",
  gi: "Γιβραλτάρ",
  gl: "Γροιλανδία",
  gm: "Γκάμπια",
  gn: "Γουινέα",
  gp: "Γουαδελούπη",
  gq: "Ισημερινή Γουινέα",
  gr: "Ελλάδα",
  gt: "Γουατεμάλα",
  gu: "Γκουάμ",
  gw: "Γουινέα Μπισάου",
  gy: "Γουιάνα",
  hk: "Χονγκ Κονγκ ΕΔΠ Κίνας",
  hn: "Ονδούρα",
  hr: "Κροατία",
  ht: "Αϊτή",
  hu: "Ουγγαρία",
  id: "Ινδονησία",
  ie: "Ιρλανδία",
  il: "Ισραήλ",
  im: "Νήσος του Μαν",
  in: "Ινδία",
  io: "Βρετανικά Εδάφη Ινδικού Ωκεανού",
  iq: "Ιράκ",
  ir: "Ιράν",
  is: "Ισλανδία",
  it: "Ιταλία",
  je: "Τζέρζι",
  jm: "Τζαμάικα",
  jo: "Ιορδανία",
  jp: "Ιαπωνία",
  ke: "Κένυα",
  kg: "Κιργιστάν",
  kh: "Καμπότζη",
  ki: "Κιριμπάτι",
  km: "Κομόρες",
  kn: "Σεν Κιτς και Νέβις",
  kp: "Βόρεια Κορέα",
  kr: "Νότια Κορέα",
  kw: "Κουβέιτ",
  ky: "Νήσοι Κέιμαν",
  kz: "Καζακστάν",
  la: "Λάος",
  lb: "Λίβανος",
  lc: "Αγία Λουκία",
  li: "Λιχτενστάιν",
  lk: "Σρι Λάνκα",
  lr: "Λιβερία",
  ls: "Λεσότο",
  lt: "Λιθουανία",
  lu: "Λουξεμβούργο",
  lv: "Λετονία",
  ly: "Λιβύη",
  ma: "Μαρόκο",
  mc: "Μονακό",
  md: "Μολδαβία",
  me: "Μαυροβούνιο",
  mf: "Άγιος Μαρτίνος (Γαλλικό τμήμα)",
  mg: "Μαδαγασκάρη",
  mh: "Νήσοι Μάρσαλ",
  mk: "Βόρεια Μακεδονία",
  ml: "Μάλι",
  mm: "Μιανμάρ (Βιρμανία)",
  mn: "Μογγολία",
  mo: "Μακάο ΕΔΠ Κίνας",
  mp: "Νήσοι Βόρειες Μαριάνες",
  mq: "Μαρτινίκα",
  mr: "Μαυριτανία",
  ms: "Μονσεράτ",
  mt: "Μάλτα",
  mu: "Μαυρίκιος",
  mv: "Μαλδίβες",
  mw: "Μαλάουι",
  mx: "Μεξικό",
  my: "Μαλαισία",
  mz: "Μοζαμβίκη",
  na: "Ναμίμπια",
  nc: "Νέα Καληδονία",
  ne: "Νίγηρας",
  nf: "Νήσος Νόρφολκ",
  ng: "Νιγηρία",
  ni: "Νικαράγουα",
  nl: "Ολλανδία",
  no: "Νορβηγία",
  np: "Νεπάλ",
  nr: "Ναουρού",
  nu: "Νιούε",
  nz: "Νέα Ζηλανδία",
  om: "Ομάν",
  pa: "Παναμάς",
  pe: "Περού",
  pf: "Γαλλική Πολυνησία",
  pg: "Παπούα Νέα Γουινέα",
  ph: "Φιλιππίνες",
  pk: "Πακιστάν",
  pl: "Πολωνία",
  pm: "Σεν Πιερ και Μικελόν",
  pr: "Πουέρτο Ρίκο",
  ps: "Παλαιστινιακά Εδάφη",
  pt: "Πορτογαλία",
  pw: "Παλάου",
  py: "Παραγουάη",
  qa: "Κατάρ",
  re: "Ρεϊνιόν",
  ro: "Ρουμανία",
  rs: "Σερβία",
  ru: "Ρωσία",
  rw: "Ρουάντα",
  sa: "Σαουδική Αραβία",
  sb: "Νήσοι Σολομώντος",
  sc: "Σεϋχέλλες",
  sd: "Σουδάν",
  se: "Σουηδία",
  sg: "Σιγκαπούρη",
  sh: "Αγία Ελένη",
  si: "Σλοβενία",
  sj: "Σβάλμπαρντ και Γιαν Μαγιέν",
  sk: "Σλοβακία",
  sl: "Σιέρα Λεόνε",
  sm: "Άγιος Μαρίνος",
  sn: "Σενεγάλη",
  so: "Σομαλία",
  sr: "Σουρινάμ",
  ss: "Νότιο Σουδάν",
  st: "Σάο Τομέ και Πρίνσιπε",
  sv: "Ελ Σαλβαδόρ",
  sx: "Άγιος Μαρτίνος (Ολλανδικό τμήμα)",
  sy: "Συρία",
  sz: "Σουαζιλάνδη",
  tc: "Νήσοι Τερκς και Κάικος",
  td: "Τσαντ",
  tg: "Τόγκο",
  th: "Ταϊλάνδη",
  tj: "Τατζικιστάν",
  tk: "Τοκελάου",
  tl: "Τιμόρ-Λέστε",
  tm: "Τουρκμενιστάν",
  tn: "Τυνησία",
  to: "Τόνγκα",
  tr: "Τουρκία",
  tt: "Τρινιντάντ και Τομπάγκο",
  tv: "Τουβαλού",
  tw: "Ταϊβάν",
  tz: "Τανζανία",
  ua: "Ουκρανία",
  ug: "Ουγκάντα",
  us: "Ηνωμένες Πολιτείες",
  uy: "Ουρουγουάη",
  uz: "Ουζμπεκιστάν",
  va: "Βατικανό",
  vc: "Άγιος Βικέντιος και Γρεναδίνες",
  ve: "Βενεζουέλα",
  vg: "Βρετανικές Παρθένες Νήσοι",
  vi: "Αμερικανικές Παρθένες Νήσοι",
  vn: "Βιετνάμ",
  vu: "Βανουάτου",
  wf: "Γουάλις και Φουτούνα",
  ws: "Σαμόα",
  ye: "Υεμένη",
  yt: "Μαγιότ",
  za: "Νότια Αφρική",
  zm: "Ζάμπια",
  zw: "Ζιμπάμπουε"
};
export default countryTranslations;
