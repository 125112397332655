const countryTranslations = {
  ad: "Андорра",
  ae: "ОАЭ",
  af: "Афганистан",
  ag: "Антигуа и Барбуда",
  ai: "Ангилья",
  al: "Албания",
  am: "Армения",
  ao: "Ангола",
  ar: "Аргентина",
  as: "Американское Самоа",
  at: "Австрия",
  au: "Австралия",
  aw: "Аруба",
  ax: "Аландские о-ва",
  az: "Азербайджан",
  ba: "Босния и Герцеговина",
  bb: "Барбадос",
  bd: "Бангладеш",
  be: "Бельгия",
  bf: "Буркина-Фасо",
  bg: "Болгария",
  bh: "Бахрейн",
  bi: "Бурунди",
  bj: "Бенин",
  bl: "Сен-Бартелеми",
  bm: "Бермудские о-ва",
  bn: "Бруней-Даруссалам",
  bo: "Боливия",
  bq: "Бонэйр, Синт-Эстатиус и Саба",
  br: "Бразилия",
  bs: "Багамы",
  bt: "Бутан",
  bw: "Ботсвана",
  by: "Беларусь",
  bz: "Белиз",
  ca: "Канада",
  cc: "Кокосовые о-ва",
  cd: "Конго - Киншаса",
  cf: "Центрально-Африканская Республика",
  cg: "Конго - Браззавиль",
  ch: "Швейцария",
  ci: "Кот-д’Ивуар",
  ck: "Острова Кука",
  cl: "Чили",
  cm: "Камерун",
  cn: "Китай",
  co: "Колумбия",
  cr: "Коста-Рика",
  cu: "Куба",
  cv: "Кабо-Верде",
  cw: "Кюрасао",
  cx: "о-в Рождества",
  cy: "Кипр",
  cz: "Чехия",
  de: "Германия",
  dj: "Джибути",
  dk: "Дания",
  dm: "Доминика",
  do: "Доминиканская Республика",
  dz: "Алжир",
  ec: "Эквадор",
  ee: "Эстония",
  eg: "Египет",
  eh: "Западная Сахара",
  er: "Эритрея",
  es: "Испания",
  et: "Эфиопия",
  fi: "Финляндия",
  fj: "Фиджи",
  fk: "Фолклендские о-ва",
  fm: "Федеративные Штаты Микронезии",
  fo: "Фарерские о-ва",
  fr: "Франция",
  ga: "Габон",
  gb: "Великобритания",
  gd: "Гренада",
  ge: "Грузия",
  gf: "Французская Гвиана",
  gg: "Гернси",
  gh: "Гана",
  gi: "Гибралтар",
  gl: "Гренландия",
  gm: "Гамбия",
  gn: "Гвинея",
  gp: "Гваделупа",
  gq: "Экваториальная Гвинея",
  gr: "Греция",
  gt: "Гватемала",
  gu: "Гуам",
  gw: "Гвинея-Бисау",
  gy: "Гайана",
  hk: "Гонконг (САР)",
  hn: "Гондурас",
  hr: "Хорватия",
  ht: "Гаити",
  hu: "Венгрия",
  id: "Индонезия",
  ie: "Ирландия",
  il: "Израиль",
  im: "о-в Мэн",
  in: "Индия",
  io: "Британская территория в Индийском океане",
  iq: "Ирак",
  ir: "Иран",
  is: "Исландия",
  it: "Италия",
  je: "Джерси",
  jm: "Ямайка",
  jo: "Иордания",
  jp: "Япония",
  ke: "Кения",
  kg: "Киргизия",
  kh: "Камбоджа",
  ki: "Кирибати",
  km: "Коморы",
  kn: "Сент-Китс и Невис",
  kp: "КНДР",
  kr: "Республика Корея",
  kw: "Кувейт",
  ky: "Острова Кайман",
  kz: "Казахстан",
  la: "Лаос",
  lb: "Ливан",
  lc: "Сент-Люсия",
  li: "Лихтенштейн",
  lk: "Шри-Ланка",
  lr: "Либерия",
  ls: "Лесото",
  lt: "Литва",
  lu: "Люксембург",
  lv: "Латвия",
  ly: "Ливия",
  ma: "Марокко",
  mc: "Монако",
  md: "Молдова",
  me: "Черногория",
  mf: "Сен-Мартен",
  mg: "Мадагаскар",
  mh: "Маршалловы Острова",
  mk: "Северная Македония",
  ml: "Мали",
  mm: "Мьянма (Бирма)",
  mn: "Монголия",
  mo: "Макао (САР)",
  mp: "Северные Марианские о-ва",
  mq: "Мартиника",
  mr: "Мавритания",
  ms: "Монтсеррат",
  mt: "Мальта",
  mu: "Маврикий",
  mv: "Мальдивы",
  mw: "Малави",
  mx: "Мексика",
  my: "Малайзия",
  mz: "Мозамбик",
  na: "Намибия",
  nc: "Новая Каледония",
  ne: "Нигер",
  nf: "о-в Норфолк",
  ng: "Нигерия",
  ni: "Никарагуа",
  nl: "Нидерланды",
  no: "Норвегия",
  np: "Непал",
  nr: "Науру",
  nu: "Ниуэ",
  nz: "Новая Зеландия",
  om: "Оман",
  pa: "Панама",
  pe: "Перу",
  pf: "Французская Полинезия",
  pg: "Папуа — Новая Гвинея",
  ph: "Филиппины",
  pk: "Пакистан",
  pl: "Польша",
  pm: "Сен-Пьер и Микелон",
  pr: "Пуэрто-Рико",
  ps: "Палестинские территории",
  pt: "Португалия",
  pw: "Палау",
  py: "Парагвай",
  qa: "Катар",
  re: "Реюньон",
  ro: "Румыния",
  rs: "Сербия",
  ru: "Россия",
  rw: "Руанда",
  sa: "Саудовская Аравия",
  sb: "Соломоновы Острова",
  sc: "Сейшельские Острова",
  sd: "Судан",
  se: "Швеция",
  sg: "Сингапур",
  sh: "о-в Св. Елены",
  si: "Словения",
  sj: "Шпицберген и Ян-Майен",
  sk: "Словакия",
  sl: "Сьерра-Леоне",
  sm: "Сан-Марино",
  sn: "Сенегал",
  so: "Сомали",
  sr: "Суринам",
  ss: "Южный Судан",
  st: "Сан-Томе и Принсипи",
  sv: "Сальвадор",
  sx: "Синт-Мартен",
  sy: "Сирия",
  sz: "Эсватини",
  tc: "о-ва Тёркс и Кайкос",
  td: "Чад",
  tg: "Того",
  th: "Таиланд",
  tj: "Таджикистан",
  tk: "Токелау",
  tl: "Восточный Тимор",
  tm: "Туркменистан",
  tn: "Тунис",
  to: "Тонга",
  tr: "Турция",
  tt: "Тринидад и Тобаго",
  tv: "Тувалу",
  tw: "Тайвань",
  tz: "Танзания",
  ua: "Украина",
  ug: "Уганда",
  us: "Соединенные Штаты",
  uy: "Уругвай",
  uz: "Узбекистан",
  va: "Ватикан",
  vc: "Сент-Винсент и Гренадины",
  ve: "Венесуэла",
  vg: "Виргинские о-ва (Великобритания)",
  vi: "Виргинские о-ва (США)",
  vn: "Вьетнам",
  vu: "Вануату",
  wf: "Уоллис и Футуна",
  ws: "Самоа",
  ye: "Йемен",
  yt: "Майотта",
  za: "Южно-Африканская Республика",
  zm: "Замбия",
  zw: "Зимбабве"
};
export default countryTranslations;
