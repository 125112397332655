const countryTranslations = {
  ad: "अँडोरा",
  ae: "संयुक्त अरब अमीरात",
  af: "अफगाणिस्तान",
  ag: "अँटिग्वा आणि बर्बुडा",
  ai: "अँग्विला",
  al: "अल्बानिया",
  am: "अर्मेनिया",
  ao: "अंगोला",
  ar: "अर्जेंटिना",
  as: "अमेरिकन सामोआ",
  at: "ऑस्ट्रिया",
  au: "ऑस्ट्रेलिया",
  aw: "अरुबा",
  ax: "अ‍ॅलँड बेटे",
  az: "अझरबैजान",
  ba: "बोस्निया अणि हर्जेगोविना",
  bb: "बार्बाडोस",
  bd: "बांगलादेश",
  be: "बेल्जियम",
  bf: "बुर्किना फासो",
  bg: "बल्गेरिया",
  bh: "बहारीन",
  bi: "बुरुंडी",
  bj: "बेनिन",
  bl: "सेंट बार्थेलेमी",
  bm: "बर्मुडा",
  bn: "ब्रुनेई",
  bo: "बोलिव्हिया",
  bq: "कॅरिबियन नेदरलँड्स",
  br: "ब्राझिल",
  bs: "बहामाज",
  bt: "भूतान",
  bw: "बोट्सवाना",
  by: "बेलारूस",
  bz: "बेलिझे",
  ca: "कॅनडा",
  cc: "कोकोस (कीलिंग) बेटे",
  cd: "काँगो - किंशासा",
  cf: "केंद्रीय अफ्रिकी प्रजासत्ताक",
  cg: "काँगो - ब्राझाविले",
  ch: "स्वित्झर्लंड",
  ci: "आयव्हरी कोस्ट",
  ck: "कुक बेटे",
  cl: "चिली",
  cm: "कॅमेरून",
  cn: "चीन",
  co: "कोलम्बिया",
  cr: "कोस्टा रिका",
  cu: "क्यूबा",
  cv: "केप व्हर्डे",
  cw: "क्युरासाओ",
  cx: "ख्रिसमस बेट",
  cy: "सायप्रस",
  cz: "झेकिया",
  de: "जर्मनी",
  dj: "जिबौटी",
  dk: "डेन्मार्क",
  dm: "डोमिनिका",
  do: "डोमिनिकन प्रजासत्ताक",
  dz: "अल्जीरिया",
  ec: "इक्वाडोर",
  ee: "एस्टोनिया",
  eg: "इजिप्त",
  eh: "पश्चिम सहारा",
  er: "एरिट्रिया",
  es: "स्पेन",
  et: "इथिओपिया",
  fi: "फिनलंड",
  fj: "फिजी",
  fk: "फॉकलंड बेटे",
  fm: "मायक्रोनेशिया",
  fo: "फेरो बेटे",
  fr: "फ्रान्स",
  ga: "गॅबॉन",
  gb: "युनायटेड किंगडम",
  gd: "ग्रेनेडा",
  ge: "जॉर्जिया",
  gf: "फ्रेंच गयाना",
  gg: "ग्वेर्नसे",
  gh: "घाना",
  gi: "जिब्राल्टर",
  gl: "ग्रीनलंड",
  gm: "गाम्बिया",
  gn: "गिनी",
  gp: "ग्वाडेलोउपे",
  gq: "इक्वेटोरियल गिनी",
  gr: "ग्रीस",
  gt: "ग्वाटेमाला",
  gu: "गुआम",
  gw: "गिनी-बिसाउ",
  gy: "गयाना",
  hk: "हाँगकाँग एसएआर चीन",
  hn: "होंडुरास",
  hr: "क्रोएशिया",
  ht: "हैती",
  hu: "हंगेरी",
  id: "इंडोनेशिया",
  ie: "आयर्लंड",
  il: "इस्त्राइल",
  im: "आयल ऑफ मॅन",
  in: "भारत",
  io: "ब्रिटिश हिंदी महासागर क्षेत्र",
  iq: "इराक",
  ir: "इराण",
  is: "आइसलँड",
  it: "इटली",
  je: "जर्सी",
  jm: "जमैका",
  jo: "जॉर्डन",
  jp: "जपान",
  ke: "केनिया",
  kg: "किरगिझस्तान",
  kh: "कंबोडिया",
  ki: "किरीबाटी",
  km: "कोमोरोज",
  kn: "सेंट किट्स आणि नेव्हिस",
  kp: "उत्तर कोरिया",
  kr: "दक्षिण कोरिया",
  kw: "कुवेत",
  ky: "केमन बेटे",
  kz: "कझाकस्तान",
  la: "लाओस",
  lb: "लेबनॉन",
  lc: "सेंट ल्यूसिया",
  li: "लिक्टेनस्टाइन",
  lk: "श्रीलंका",
  lr: "लायबेरिया",
  ls: "लेसोथो",
  lt: "लिथुआनिया",
  lu: "लक्झेंबर्ग",
  lv: "लात्विया",
  ly: "लिबिया",
  ma: "मोरोक्को",
  mc: "मोनॅको",
  md: "मोल्डोव्हा",
  me: "मोंटेनेग्रो",
  mf: "सेंट मार्टिन",
  mg: "मादागास्कर",
  mh: "मार्शल बेटे",
  mk: "उत्तर मॅसेडोनिया",
  ml: "माली",
  mm: "म्यानमार (बर्मा)",
  mn: "मंगोलिया",
  mo: "मकाओ एसएआर चीन",
  mp: "उत्तरी मारियाना बेटे",
  mq: "मार्टिनिक",
  mr: "मॉरिटानिया",
  ms: "मॉन्ट्सेराट",
  mt: "माल्टा",
  mu: "मॉरिशस",
  mv: "मालदीव",
  mw: "मलावी",
  mx: "मेक्सिको",
  my: "मलेशिया",
  mz: "मोझाम्बिक",
  na: "नामिबिया",
  nc: "न्यू कॅलेडोनिया",
  ne: "नाइजर",
  nf: "नॉरफॉक बेट",
  ng: "नायजेरिया",
  ni: "निकाराग्वा",
  nl: "नेदरलँड",
  no: "नॉर्वे",
  np: "नेपाळ",
  nr: "नाउरू",
  nu: "नीयू",
  nz: "न्यूझीलंड",
  om: "ओमान",
  pa: "पनामा",
  pe: "पेरू",
  pf: "फ्रेंच पॉलिनेशिया",
  pg: "पापुआ न्यू गिनी",
  ph: "फिलिपिन्स",
  pk: "पाकिस्तान",
  pl: "पोलंड",
  pm: "सेंट पियरे आणि मिक्वेलोन",
  pr: "प्युएर्तो रिको",
  ps: "पॅलेस्टिनियन प्रदेश",
  pt: "पोर्तुगाल",
  pw: "पलाऊ",
  py: "पराग्वे",
  qa: "कतार",
  re: "रियुनियन",
  ro: "रोमानिया",
  rs: "सर्बिया",
  ru: "रशिया",
  rw: "रवांडा",
  sa: "सौदी अरब",
  sb: "सोलोमन बेटे",
  sc: "सेशेल्स",
  sd: "सुदान",
  se: "स्वीडन",
  sg: "सिंगापूर",
  sh: "सेंट हेलेना",
  si: "स्लोव्हेनिया",
  sj: "स्वालबर्ड आणि जान मायेन",
  sk: "स्लोव्हाकिया",
  sl: "सिएरा लिओन",
  sm: "सॅन मरीनो",
  sn: "सेनेगल",
  so: "सोमालिया",
  sr: "सुरिनाम",
  ss: "दक्षिण सुदान",
  st: "साओ टोम आणि प्रिंसिपे",
  sv: "अल साल्वाडोर",
  sx: "सिंट मार्टेन",
  sy: "सीरिया",
  sz: "इस्वातिनी",
  tc: "टर्क्स आणि कैकोस बेटे",
  td: "चाड",
  tg: "टोगो",
  th: "थायलंड",
  tj: "ताजिकिस्तान",
  tk: "तोकेलाउ",
  tl: "तिमोर-लेस्ते",
  tm: "तुर्कमेनिस्तान",
  tn: "ट्यूनिशिया",
  to: "टोंगा",
  tr: "तुर्की",
  tt: "त्रिनिदाद आणि टोबॅगो",
  tv: "टुवालु",
  tw: "तैवान",
  tz: "टांझानिया",
  ua: "युक्रेन",
  ug: "युगांडा",
  us: "युनायटेड स्टेट्स",
  uy: "उरुग्वे",
  uz: "उझबेकिस्तान",
  va: "व्हॅटिकन सिटी",
  vc: "सेंट व्हिन्सेंट आणि ग्रेनडाइन्स",
  ve: "व्हेनेझुएला",
  vg: "ब्रिटिश व्हर्जिन बेटे",
  vi: "यू.एस. व्हर्जिन बेटे",
  vn: "व्हिएतनाम",
  vu: "वानुआतु",
  wf: "वालिस आणि फ्यूचूना",
  ws: "सामोआ",
  ye: "येमेन",
  yt: "मायोट्टे",
  za: "दक्षिण आफ्रिका",
  zm: "झाम्बिया",
  zw: "झिम्बाब्वे"
};
export default countryTranslations;
