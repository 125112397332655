const countryTranslations = {
  ad: "Andorra",
  ae: "Araabia Ühendemiraadid",
  af: "Afganistan",
  ag: "Antigua ja Barbuda",
  ai: "Anguilla",
  al: "Albaania",
  am: "Armeenia",
  ao: "Angola",
  ar: "Argentina",
  as: "Ameerika Samoa",
  at: "Austria",
  au: "Austraalia",
  aw: "Aruba",
  ax: "Ahvenamaa",
  az: "Aserbaidžaan",
  ba: "Bosnia ja Hertsegoviina",
  bb: "Barbados",
  bd: "Bangladesh",
  be: "Belgia",
  bf: "Burkina Faso",
  bg: "Bulgaaria",
  bh: "Bahrein",
  bi: "Burundi",
  bj: "Benin",
  bl: "Saint-Barthélemy",
  bm: "Bermuda",
  bn: "Brunei",
  bo: "Boliivia",
  bq: "Kariibi Madalmaad",
  br: "Brasiilia",
  bs: "Bahama",
  bt: "Bhutan",
  bw: "Botswana",
  by: "Valgevene",
  bz: "Belize",
  ca: "Kanada",
  cc: "Kookossaared",
  cd: "Kongo-Kinshasa",
  cf: "Kesk-Aafrika Vabariik",
  cg: "Kongo-Brazzaville",
  ch: "Šveits",
  ci: "Elevandiluurannik",
  ck: "Cooki saared",
  cl: "Tšiili",
  cm: "Kamerun",
  cn: "Hiina",
  co: "Colombia",
  cr: "Costa Rica",
  cu: "Kuuba",
  cv: "Roheneemesaared",
  cw: "Curaçao",
  cx: "Jõulusaared",
  cy: "Küpros",
  cz: "Tšehhi",
  de: "Saksamaa",
  dj: "Djibouti",
  dk: "Taani",
  dm: "Dominica",
  do: "Dominikaani Vabariik",
  dz: "Alžeeria",
  ec: "Ecuador",
  ee: "Eesti",
  eg: "Egiptus",
  eh: "Lääne-Sahara",
  er: "Eritrea",
  es: "Hispaania",
  et: "Etioopia",
  fi: "Soome",
  fj: "Fidži",
  fk: "Falklandi saared",
  fm: "Mikroneesia",
  fo: "Fääri saared",
  fr: "Prantsusmaa",
  ga: "Gabon",
  gb: "Ühendkuningriik",
  gd: "Grenada",
  ge: "Gruusia",
  gf: "Prantsuse Guajaana",
  gg: "Guernsey",
  gh: "Ghana",
  gi: "Gibraltar",
  gl: "Gröönimaa",
  gm: "Gambia",
  gn: "Guinea",
  gp: "Guadeloupe",
  gq: "Ekvatoriaal-Guinea",
  gr: "Kreeka",
  gt: "Guatemala",
  gu: "Guam",
  gw: "Guinea-Bissau",
  gy: "Guyana",
  hk: "Hongkongi erihalduspiirkond",
  hn: "Honduras",
  hr: "Horvaatia",
  ht: "Haiti",
  hu: "Ungari",
  id: "Indoneesia",
  ie: "Iirimaa",
  il: "Iisrael",
  im: "Mani saar",
  in: "India",
  io: "Briti India ookeani ala",
  iq: "Iraak",
  ir: "Iraan",
  is: "Island",
  it: "Itaalia",
  je: "Jersey",
  jm: "Jamaica",
  jo: "Jordaania",
  jp: "Jaapan",
  ke: "Keenia",
  kg: "Kõrgõzstan",
  kh: "Kambodža",
  ki: "Kiribati",
  km: "Komoorid",
  kn: "Saint Kitts ja Nevis",
  kp: "Põhja-Korea",
  kr: "Lõuna-Korea",
  kw: "Kuveit",
  ky: "Kaimanisaared",
  kz: "Kasahstan",
  la: "Laos",
  lb: "Liibanon",
  lc: "Saint Lucia",
  li: "Liechtenstein",
  lk: "Sri Lanka",
  lr: "Libeeria",
  ls: "Lesotho",
  lt: "Leedu",
  lu: "Luksemburg",
  lv: "Läti",
  ly: "Liibüa",
  ma: "Maroko",
  mc: "Monaco",
  md: "Moldova",
  me: "Montenegro",
  mf: "Saint-Martin",
  mg: "Madagaskar",
  mh: "Marshalli saared",
  mk: "Põhja-Makedoonia",
  ml: "Mali",
  mm: "Myanmar",
  mn: "Mongoolia",
  mo: "Macau erihalduspiirkond",
  mp: "Põhja-Mariaanid",
  mq: "Martinique",
  mr: "Mauritaania",
  ms: "Montserrat",
  mt: "Malta",
  mu: "Mauritius",
  mv: "Maldiivid",
  mw: "Malawi",
  mx: "Mehhiko",
  my: "Malaisia",
  mz: "Mosambiik",
  na: "Namiibia",
  nc: "Uus-Kaledoonia",
  ne: "Niger",
  nf: "Norfolki saar",
  ng: "Nigeeria",
  ni: "Nicaragua",
  nl: "Holland",
  no: "Norra",
  np: "Nepal",
  nr: "Nauru",
  nu: "Niue",
  nz: "Uus-Meremaa",
  om: "Omaan",
  pa: "Panama",
  pe: "Peruu",
  pf: "Prantsuse Polüneesia",
  pg: "Paapua Uus-Guinea",
  ph: "Filipiinid",
  pk: "Pakistan",
  pl: "Poola",
  pm: "Saint-Pierre ja Miquelon",
  pr: "Puerto Rico",
  ps: "Palestiina alad",
  pt: "Portugal",
  pw: "Belau",
  py: "Paraguay",
  qa: "Katar",
  re: "Réunion",
  ro: "Rumeenia",
  rs: "Serbia",
  ru: "Venemaa",
  rw: "Rwanda",
  sa: "Saudi Araabia",
  sb: "Saalomoni saared",
  sc: "Seišellid",
  sd: "Sudaan",
  se: "Rootsi",
  sg: "Singapur",
  sh: "Saint Helena",
  si: "Sloveenia",
  sj: "Svalbard ja Jan Mayen",
  sk: "Slovakkia",
  sl: "Sierra Leone",
  sm: "San Marino",
  sn: "Senegal",
  so: "Somaalia",
  sr: "Suriname",
  ss: "Lõuna-Sudaan",
  st: "São Tomé ja Príncipe",
  sv: "El Salvador",
  sx: "Sint Maarten",
  sy: "Süüria",
  sz: "Eswatini",
  tc: "Turks ja Caicos",
  td: "Tšaad",
  tg: "Togo",
  th: "Tai",
  tj: "Tadžikistan",
  tk: "Tokelau",
  tl: "Ida-Timor",
  tm: "Türkmenistan",
  tn: "Tuneesia",
  to: "Tonga",
  tr: "Türgi",
  tt: "Trinidad ja Tobago",
  tv: "Tuvalu",
  tw: "Taiwan",
  tz: "Tansaania",
  ua: "Ukraina",
  ug: "Uganda",
  us: "Ameerika Ühendriigid",
  uy: "Uruguay",
  uz: "Usbekistan",
  va: "Vatikan",
  vc: "Saint Vincent ja Grenadiinid",
  ve: "Venezuela",
  vg: "Briti Neitsisaared",
  vi: "USA Neitsisaared",
  vn: "Vietnam",
  vu: "Vanuatu",
  wf: "Wallis ja Futuna",
  ws: "Samoa",
  ye: "Jeemen",
  yt: "Mayotte",
  za: "Lõuna-Aafrika",
  zm: "Sambia",
  zw: "Zimbabwe",
};
export default countryTranslations;
