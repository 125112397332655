import { ru } from '../vendor/i18n'

const phoneInputs = document.querySelectorAll('.js-intl-tel input');

const initInternationalTel = () => {
  if (phoneInputs.length) {
    phoneInputs.forEach((input) => {
      // eslint-disable-next-line no-undef
      const originalName = input.name;
      input.name = '_' + originalName;

      intlTelInput(input, {
        i18n: ru,
        countrySearch: false,
        initialCountry: 'ru',
        separateDialCode: true,
        strictMode: true,
        nationalMode: true,
        autoPlaceholder: 'aggressive',
        hiddenInput: () => ({ phone: originalName }),
        countryOrder: ['ru', 'by', 'kz', 'cn']
      });
    });
  }
};

export default initInternationalTel;

