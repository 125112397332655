import {closeModal, openModal, initializeModal} from '../modals';

const inputBlocks = document.querySelectorAll('.input-file');
const errorModal = document.querySelector('.js-error-modal');
const errorContentBlock = document.querySelector('.js-error-modal .success-modal__content');
const isEnLang = (document.querySelectorAll('.site-id-s2').length !== 0);

const returnErrorTemplate = (size = '') => {
  return isEnLang ? `<h2>Error adding file.</h2><p>Maximum file size - ${size}</p>` : `<h2>Ошибка добавления файла.</h2><p>Максимальный размер файлов - ${size}</p>`;
};

const initInputFile = () => {

  if (!inputBlocks)
    return;

  const getElementIndex = (element) => {
    return Array.prototype.indexOf.call(element.parentNode.children, element);
  };

  const fileSize = (size) => {
    let e = (Math.log(size) / Math.log(1e3)) | 0;
    return +(size / Math.pow(1e3, e)).toFixed(2) + ' ' + ('kMGTPEZY'[e - 1] || '') + 'B';
  };

  const removeFile = (input, indexFile) => {
    let dt = new DataTransfer();

    Array.from(input.files).forEach((file, i) => {
      if (i !== indexFile) {
        dt.items.add(input.files[i]);
      }
    });
    input.files = dt.files;

    return dt;
  };

  inputBlocks.forEach(block => {
    const input = block.querySelector('input'),
      acceptList = input.getAttribute('accept')?.split(','),
      totalSize = +input.dataset?.totalSize || 0,
      listBlock = block.querySelector('.input-file__list'),
      form = block.closest('form');

    let filesList = new DataTransfer();

    form.addEventListener('reset', () => {
      filesList = new DataTransfer();
      listBlock.innerHTML = '';
    });

    input.addEventListener('change', () => {
      let files = input.files,
        totalFilesSize = 0;
      listBlock.innerHTML = '';
      Array.from(files).forEach(file => {
        totalFilesSize += file.size;

        if (((acceptList && acceptList.includes(file.type)) || !acceptList)
          && ((totalSize && totalSize >= totalFilesSize)) || !totalSize) {
          filesList.items.add(file);
        }
      });

      Array.from(filesList.files).forEach(file => {
        let htmlFile = `<div class="input-file__item"><span class="input-file__name">${file.name} (${fileSize(file.size)})</span><button class="input-file__delete" type="button"><svg width="14" height="15"><use xlink:href="/local/templates/gms/img/sprite_auto.svg#basket"></use></svg><span>Удалить</span></button></div>`;
        listBlock.insertAdjacentHTML('beforeend', htmlFile);
      });

      if (totalSize && totalSize <= totalFilesSize) {
        errorContentBlock.innerHTML = returnErrorTemplate(fileSize(totalSize));
        openModal(errorModal, '', false);
        initializeModal(errorModal, false);
      }

      input.files = filesList.files;
    });

    block.addEventListener('click', event => {
      if (event.target.closest('.input-file__delete')) {
        filesList = removeFile(input, getElementIndex(event.target.closest('.input-file__item')));
        event.target.closest('.input-file__item').remove();
      }
    });

  });
};


export default initInputFile;
