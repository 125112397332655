const countryTranslations = {
  ad: "انڈورا",
  ae: "متحدہ عرب امارات",
  af: "افغانستان",
  ag: "انٹیگوا اور باربودا",
  ai: "انگوئیلا",
  al: "البانیہ",
  am: "آرمینیا",
  ao: "انگولا",
  ar: "ارجنٹینا",
  as: "امریکی ساموآ",
  at: "آسٹریا",
  au: "آسٹریلیا",
  aw: "اروبا",
  ax: "آلینڈ آئلینڈز",
  az: "آذربائیجان",
  ba: "بوسنیا اور ہرزیگووینا",
  bb: "بارباڈوس",
  bd: "بنگلہ دیش",
  be: "بیلجیم",
  bf: "برکینا فاسو",
  bg: "بلغاریہ",
  bh: "بحرین",
  bi: "برونڈی",
  bj: "بینن",
  bl: "سینٹ برتھلیمی",
  bm: "برمودا",
  bn: "برونائی",
  bo: "بولیویا",
  bq: "کریبیائی نیدرلینڈز",
  br: "برازیل",
  bs: "بہاماس",
  bt: "بھوٹان",
  bw: "بوتسوانا",
  by: "بیلاروس",
  bz: "بیلائز",
  ca: "کینیڈا",
  cc: "کوکوس (کیلنگ) جزائر",
  cd: "کانگو - کنشاسا",
  cf: "وسط افریقی جمہوریہ",
  cg: "کانگو - برازاویلے",
  ch: "سوئٹزر لینڈ",
  ci: "کوٹ ڈی آئیوری",
  ck: "کک آئلینڈز",
  cl: "چلی",
  cm: "کیمرون",
  cn: "چین",
  co: "کولمبیا",
  cr: "کوسٹا ریکا",
  cu: "کیوبا",
  cv: "کیپ ورڈی",
  cw: "کیوراکاؤ",
  cx: "جزیرہ کرسمس",
  cy: "قبرص",
  cz: "چیکیا",
  de: "جرمنی",
  dj: "جبوتی",
  dk: "ڈنمارک",
  dm: "ڈومنیکا",
  do: "جمہوریہ ڈومينيکن",
  dz: "الجیریا",
  ec: "ایکواڈور",
  ee: "اسٹونیا",
  eg: "مصر",
  eh: "مغربی صحارا",
  er: "اریٹیریا",
  es: "ہسپانیہ",
  et: "ایتھوپیا",
  fi: "فن لینڈ",
  fj: "فجی",
  fk: "فاکلینڈ جزائر",
  fm: "مائکرونیشیا",
  fo: "جزائر فارو",
  fr: "فرانس",
  ga: "گیبون",
  gb: "سلطنت متحدہ",
  gd: "گریناڈا",
  ge: "جارجیا",
  gf: "فرینچ گیانا",
  gg: "گوئرنسی",
  gh: "گھانا",
  gi: "جبل الطارق",
  gl: "گرین لینڈ",
  gm: "گیمبیا",
  gn: "گنی",
  gp: "گواڈیلوپ",
  gq: "استوائی گیانا",
  gr: "یونان",
  gt: "گواٹے مالا",
  gu: "گوام",
  gw: "گنی بساؤ",
  gy: "گیانا",
  hk: "ہانگ کانگ SAR چین",
  hn: "ہونڈاروس",
  hr: "کروشیا",
  ht: "ہیٹی",
  hu: "ہنگری",
  id: "انڈونیشیا",
  ie: "آئرلینڈ",
  il: "اسرائیل",
  im: "آئل آف مین",
  in: "بھارت",
  io: "برطانوی بحر ہند کا علاقہ",
  iq: "عراق",
  ir: "ایران",
  is: "آئس لینڈ",
  it: "اٹلی",
  je: "جرسی",
  jm: "جمائیکا",
  jo: "اردن",
  jp: "جاپان",
  ke: "کینیا",
  kg: "کرغزستان",
  kh: "کمبوڈیا",
  ki: "کریباتی",
  km: "کوموروس",
  kn: "سینٹ کٹس اور نیویس",
  kp: "شمالی کوریا",
  kr: "جنوبی کوریا",
  kw: "کویت",
  ky: "کیمین آئلینڈز",
  kz: "قزاخستان",
  la: "لاؤس",
  lb: "لبنان",
  lc: "سینٹ لوسیا",
  li: "لیشٹنسٹائن",
  lk: "سری لنکا",
  lr: "لائبیریا",
  ls: "لیسوتھو",
  lt: "لیتھونیا",
  lu: "لکسمبرگ",
  lv: "لٹویا",
  ly: "لیبیا",
  ma: "مراکش",
  mc: "موناکو",
  md: "مالدووا",
  me: "مونٹے نیگرو",
  mf: "سینٹ مارٹن",
  mg: "مڈغاسکر",
  mh: "مارشل آئلینڈز",
  mk: "شمالی مقدونیہ",
  ml: "مالی",
  mm: "میانمار (برما)",
  mn: "منگولیا",
  mo: "مکاؤ SAR چین",
  mp: "شمالی ماریانا آئلینڈز",
  mq: "مارٹینک",
  mr: "موریطانیہ",
  ms: "مونٹسیراٹ",
  mt: "مالٹا",
  mu: "ماریشس",
  mv: "مالدیپ",
  mw: "ملاوی",
  mx: "میکسیکو",
  my: "ملائشیا",
  mz: "موزمبیق",
  na: "نامیبیا",
  nc: "نیو کلیڈونیا",
  ne: "نائجر",
  nf: "نارفوک آئلینڈ",
  ng: "نائجیریا",
  ni: "نکاراگووا",
  nl: "نیدر لینڈز",
  no: "ناروے",
  np: "نیپال",
  nr: "نؤرو",
  nu: "نیئو",
  nz: "نیوزی لینڈ",
  om: "عمان",
  pa: "پانامہ",
  pe: "پیرو",
  pf: "فرانسیسی پولینیشیا",
  pg: "پاپوآ نیو گنی",
  ph: "فلپائن",
  pk: "پاکستان",
  pl: "پولینڈ",
  pm: "سینٹ پیئر اور میکلیئون",
  pr: "پیورٹو ریکو",
  ps: "فلسطینی خطے",
  pt: "پرتگال",
  pw: "پلاؤ",
  py: "پیراگوئے",
  qa: "قطر",
  re: "ری یونین",
  ro: "رومانیہ",
  rs: "سربیا",
  ru: "روس",
  rw: "روانڈا",
  sa: "سعودی عرب",
  sb: "سولومن آئلینڈز",
  sc: "سشلیز",
  sd: "سوڈان",
  se: "سویڈن",
  sg: "سنگاپور",
  sh: "سینٹ ہیلینا",
  si: "سلووینیا",
  sj: "سوالبرڈ اور جان ماین",
  sk: "سلوواکیہ",
  sl: "سیرالیون",
  sm: "سان مارینو",
  sn: "سینیگل",
  so: "صومالیہ",
  sr: "سورینام",
  ss: "جنوبی سوڈان",
  st: "ساؤ ٹومے اور پرنسپے",
  sv: "ال سلواڈور",
  sx: "سنٹ مارٹن",
  sy: "شام",
  sz: "سواتنی",
  tc: "ٹرکس اور کیکوس جزائر",
  td: "چاڈ",
  tg: "ٹوگو",
  th: "تھائی لینڈ",
  tj: "تاجکستان",
  tk: "ٹوکیلاؤ",
  tl: "تیمور لیسٹ",
  tm: "ترکمانستان",
  tn: "تونس",
  to: "ٹونگا",
  tr: "ترکی",
  tt: "ترینیداد اور ٹوباگو",
  tv: "ٹووالو",
  tw: "تائیوان",
  tz: "تنزانیہ",
  ua: "یوکرین",
  ug: "یوگنڈا",
  us: "ریاست ہائے متحدہ امریکہ",
  uy: "یوروگوئے",
  uz: "ازبکستان",
  va: "ویٹیکن سٹی",
  vc: "سینٹ ونسنٹ اور گرینیڈائنز",
  ve: "وینزوئیلا",
  vg: "برٹش ورجن آئلینڈز",
  vi: "امریکی ورجن آئلینڈز",
  vn: "ویتنام",
  vu: "وینوآٹو",
  wf: "ویلیز اور فیوٹیونا",
  ws: "ساموآ",
  ye: "یمن",
  yt: "مایوٹ",
  za: "جنوبی افریقہ",
  zm: "زامبیا",
  zw: "زمبابوے"
};
export default countryTranslations;
