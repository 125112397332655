const countryTranslations = {
  ad: "Andorra",
  ae: "Uni Emirat Arab",
  af: "Afganistan",
  ag: "Antigua dan Barbuda",
  ai: "Anguilla",
  al: "Albania",
  am: "Armenia",
  ao: "Angola",
  ar: "Argentina",
  as: "Samoa Amerika",
  at: "Austria",
  au: "Australia",
  aw: "Aruba",
  ax: "Kepulauan Aland",
  az: "Azerbaijan",
  ba: "Bosnia dan Herzegovina",
  bb: "Barbados",
  bd: "Bangladesh",
  be: "Belgia",
  bf: "Burkina Faso",
  bg: "Bulgaria",
  bh: "Bahrain",
  bi: "Burundi",
  bj: "Benin",
  bl: "Saint Barthélemy",
  bm: "Bermuda",
  bn: "Brunei",
  bo: "Bolivia",
  bq: "Belanda Karibia",
  br: "Brasil",
  bs: "Bahama",
  bt: "Bhutan",
  bw: "Botswana",
  by: "Belarus",
  bz: "Belize",
  ca: "Kanada",
  cc: "Kepulauan Cocos (Keeling)",
  cd: "Kongo - Kinshasa",
  cf: "Republik Afrika Tengah",
  cg: "Kongo - Brazzaville",
  ch: "Swiss",
  ci: "Côte d’Ivoire",
  ck: "Kepulauan Cook",
  cl: "Cile",
  cm: "Kamerun",
  cn: "Tiongkok",
  co: "Kolombia",
  cr: "Kosta Rika",
  cu: "Kuba",
  cv: "Tanjung Verde",
  cw: "Curaçao",
  cx: "Pulau Natal",
  cy: "Siprus",
  cz: "Ceko",
  de: "Jerman",
  dj: "Jibuti",
  dk: "Denmark",
  dm: "Dominika",
  do: "Republik Dominika",
  dz: "Aljazair",
  ec: "Ekuador",
  ee: "Estonia",
  eg: "Mesir",
  eh: "Sahara Barat",
  er: "Eritrea",
  es: "Spanyol",
  et: "Etiopia",
  fi: "Finlandia",
  fj: "Fiji",
  fk: "Kepulauan Falkland",
  fm: "Mikronesia",
  fo: "Kepulauan Faroe",
  fr: "Prancis",
  ga: "Gabon",
  gb: "Inggris Raya",
  gd: "Grenada",
  ge: "Georgia",
  gf: "Guyana Prancis",
  gg: "Guernsey",
  gh: "Ghana",
  gi: "Gibraltar",
  gl: "Grinlandia",
  gm: "Gambia",
  gn: "Guinea",
  gp: "Guadeloupe",
  gq: "Guinea Ekuatorial",
  gr: "Yunani",
  gt: "Guatemala",
  gu: "Guam",
  gw: "Guinea-Bissau",
  gy: "Guyana",
  hk: "Hong Kong DAK Tiongkok",
  hn: "Honduras",
  hr: "Kroasia",
  ht: "Haiti",
  hu: "Hungaria",
  id: "Indonesia",
  ie: "Irlandia",
  il: "Israel",
  im: "Pulau Man",
  in: "India",
  io: "Wilayah Inggris di Samudra Hindia",
  iq: "Irak",
  ir: "Iran",
  is: "Islandia",
  it: "Italia",
  je: "Jersey",
  jm: "Jamaika",
  jo: "Yordania",
  jp: "Jepang",
  ke: "Kenya",
  kg: "Kirgistan",
  kh: "Kamboja",
  ki: "Kiribati",
  km: "Komoro",
  kn: "Saint Kitts dan Nevis",
  kp: "Korea Utara",
  kr: "Korea Selatan",
  kw: "Kuwait",
  ky: "Kepulauan Cayman",
  kz: "Kazakstan",
  la: "Laos",
  lb: "Lebanon",
  lc: "Saint Lucia",
  li: "Liechtenstein",
  lk: "Sri Lanka",
  lr: "Liberia",
  ls: "Lesotho",
  lt: "Lituania",
  lu: "Luksemburg",
  lv: "Latvia",
  ly: "Libia",
  ma: "Maroko",
  mc: "Monako",
  md: "Moldova",
  me: "Montenegro",
  mf: "Saint Martin",
  mg: "Madagaskar",
  mh: "Kepulauan Marshall",
  mk: "Makedonia Utara",
  ml: "Mali",
  mm: "Myanmar (Burma)",
  mn: "Mongolia",
  mo: "Makau DAK Tiongkok",
  mp: "Kepulauan Mariana Utara",
  mq: "Martinik",
  mr: "Mauritania",
  ms: "Montserrat",
  mt: "Malta",
  mu: "Mauritius",
  mv: "Maladewa",
  mw: "Malawi",
  mx: "Meksiko",
  my: "Malaysia",
  mz: "Mozambik",
  na: "Namibia",
  nc: "Kaledonia Baru",
  ne: "Niger",
  nf: "Kepulauan Norfolk",
  ng: "Nigeria",
  ni: "Nikaragua",
  nl: "Belanda",
  no: "Norwegia",
  np: "Nepal",
  nr: "Nauru",
  nu: "Niue",
  nz: "Selandia Baru",
  om: "Oman",
  pa: "Panama",
  pe: "Peru",
  pf: "Polinesia Prancis",
  pg: "Papua Nugini",
  ph: "Filipina",
  pk: "Pakistan",
  pl: "Polandia",
  pm: "Saint Pierre dan Miquelon",
  pr: "Puerto Riko",
  ps: "Wilayah Palestina",
  pt: "Portugal",
  pw: "Palau",
  py: "Paraguay",
  qa: "Qatar",
  re: "Réunion",
  ro: "Rumania",
  rs: "Serbia",
  ru: "Rusia",
  rw: "Rwanda",
  sa: "Arab Saudi",
  sb: "Kepulauan Solomon",
  sc: "Seychelles",
  sd: "Sudan",
  se: "Swedia",
  sg: "Singapura",
  sh: "Saint Helena",
  si: "Slovenia",
  sj: "Kepulauan Svalbard dan Jan Mayen",
  sk: "Slovakia",
  sl: "Sierra Leone",
  sm: "San Marino",
  sn: "Senegal",
  so: "Somalia",
  sr: "Suriname",
  ss: "Sudan Selatan",
  st: "Sao Tome dan Principe",
  sv: "El Salvador",
  sx: "Sint Maarten",
  sy: "Suriah",
  sz: "eSwatini",
  tc: "Kepulauan Turks dan Caicos",
  td: "Cad",
  tg: "Togo",
  th: "Thailand",
  tj: "Tajikistan",
  tk: "Tokelau",
  tl: "Timor Leste",
  tm: "Turkimenistan",
  tn: "Tunisia",
  to: "Tonga",
  tr: "Turki",
  tt: "Trinidad dan Tobago",
  tv: "Tuvalu",
  tw: "Taiwan",
  tz: "Tanzania",
  ua: "Ukraina",
  ug: "Uganda",
  us: "Amerika Serikat",
  uy: "Uruguay",
  uz: "Uzbekistan",
  va: "Vatikan",
  vc: "Saint Vincent dan Grenadine",
  ve: "Venezuela",
  vg: "Kepulauan Virgin Britania Raya",
  vi: "Kepulauan Virgin Amerika Serikat",
  vn: "Vietnam",
  vu: "Vanuatu",
  wf: "Kepulauan Wallis dan Futuna",
  ws: "Samoa",
  ye: "Yaman",
  yt: "Mayotte",
  za: "Afrika Selatan",
  zm: "Zambia",
  zw: "Zimbabwe"
};
export default countryTranslations;
