const countryTranslations = {
  ad: "Андорра",
  ae: "Обʼєднані Арабські Емірати",
  af: "Афганістан",
  ag: "Антиґуа і Барбуда",
  ai: "Анґілья",
  al: "Албанія",
  am: "Вірменія",
  ao: "Ангола",
  ar: "Аргентина",
  as: "Американське Самоа",
  at: "Австрія",
  au: "Австралія",
  aw: "Аруба",
  ax: "Аландські Острови",
  az: "Азербайджан",
  ba: "Боснія і Герцеґовина",
  bb: "Барбадос",
  bd: "Бангладеш",
  be: "Бельґія",
  bf: "Буркіна-Фасо",
  bg: "Болгарія",
  bh: "Бахрейн",
  bi: "Бурунді",
  bj: "Бенін",
  bl: "Сен-Бартельмі",
  bm: "Бермудські Острови",
  bn: "Бруней",
  bo: "Болівія",
  bq: "Нідерландські Карибські острови",
  br: "Бразілія",
  bs: "Багамські Острови",
  bt: "Бутан",
  bw: "Ботсвана",
  by: "Білорусь",
  bz: "Беліз",
  ca: "Канада",
  cc: "Кокосові (Кілінґ) Острови",
  cd: "Конго – Кіншаса",
  cf: "Центральноафриканська Республіка",
  cg: "Конго – Браззавіль",
  ch: "Швейцарія",
  ci: "Кот-дʼІвуар",
  ck: "Острови Кука",
  cl: "Чілі",
  cm: "Камерун",
  cn: "Китай",
  co: "Колумбія",
  cr: "Коста-Ріка",
  cu: "Куба",
  cv: "Кабо-Верде",
  cw: "Кюрасао",
  cx: "Острів Різдва",
  cy: "Кіпр",
  cz: "Чехія",
  de: "Німеччина",
  dj: "Джибуті",
  dk: "Данія",
  dm: "Домініка",
  do: "Домініканська Республіка",
  dz: "Алжир",
  ec: "Еквадор",
  ee: "Естонія",
  eg: "Єгипет",
  eh: "Західна Сахара",
  er: "Еритрея",
  es: "Іспанія",
  et: "Ефіопія",
  fi: "Фінляндія",
  fj: "Фіджі",
  fk: "Фолклендські Острови",
  fm: "Мікронезія",
  fo: "Фарерські Острови",
  fr: "Франція",
  ga: "Габон",
  gb: "Велика Британія",
  gd: "Ґренада",
  ge: "Грузія",
  gf: "Французька Ґвіана",
  gg: "Ґернсі",
  gh: "Гана",
  gi: "Ґібралтар",
  gl: "Ґренландія",
  gm: "Гамбія",
  gn: "Гвінея",
  gp: "Ґваделупа",
  gq: "Екваторіальна Гвінея",
  gr: "Греція",
  gt: "Ґватемала",
  gu: "Ґуам",
  gw: "Гвінея-Бісау",
  gy: "Ґайана",
  hk: "Гонконг, О.А.Р. Китаю",
  hn: "Гондурас",
  hr: "Хорватія",
  ht: "Гаїті",
  hu: "Угорщина",
  id: "Індонезія",
  ie: "Ірландія",
  il: "Ізраїль",
  im: "Острів Мен",
  in: "Індія",
  io: "Британська територія в Індійському Океані",
  iq: "Ірак",
  ir: "Іран",
  is: "Ісландія",
  it: "Італія",
  je: "Джерсі",
  jm: "Ямайка",
  jo: "Йорданія",
  jp: "Японія",
  ke: "Кенія",
  kg: "Киргизстан",
  kh: "Камбоджа",
  ki: "Кірібаті",
  km: "Комори",
  kn: "Сент-Кітс і Невіс",
  kp: "Північна Корея",
  kr: "Південна Корея",
  kw: "Кувейт",
  ky: "Кайманові Острови",
  kz: "Казахстан",
  la: "Лаос",
  lb: "Ліван",
  lc: "Сент-Люсія",
  li: "Ліхтенштейн",
  lk: "Шрі-Ланка",
  lr: "Ліберія",
  ls: "Лесото",
  lt: "Литва",
  lu: "Люксембурґ",
  lv: "Латвія",
  ly: "Лівія",
  ma: "Марокко",
  mc: "Монако",
  md: "Молдова",
  me: "Чорногорія",
  mf: "Сен-Мартен",
  mg: "Мадагаскар",
  mh: "Маршаллові Острови",
  mk: "Північна Македонія",
  ml: "Малі",
  mm: "Мʼянма (Бірма)",
  mn: "Монголія",
  mo: "Макао, О.А.Р Китаю",
  mp: "Північні Маріанські Острови",
  mq: "Мартініка",
  mr: "Мавританія",
  ms: "Монтсеррат",
  mt: "Мальта",
  mu: "Маврікій",
  mv: "Мальдіви",
  mw: "Малаві",
  mx: "Мексика",
  my: "Малайзія",
  mz: "Мозамбік",
  na: "Намібія",
  nc: "Нова Каледонія",
  ne: "Нігер",
  nf: "Острів Норфолк",
  ng: "Нігерія",
  ni: "Нікараґуа",
  nl: "Нідерланди",
  no: "Норвеґія",
  np: "Непал",
  nr: "Науру",
  nu: "Ніуе",
  nz: "Нова Зеландія",
  om: "Оман",
  pa: "Панама",
  pe: "Перу",
  pf: "Французька Полінезія",
  pg: "Папуа-Нова Ґвінея",
  ph: "Філіппіни",
  pk: "Пакистан",
  pl: "Польща",
  pm: "Сен-Пʼєр і Мікелон",
  pr: "Пуерто-Ріко",
  ps: "Палестинські території",
  pt: "Портуґалія",
  pw: "Палау",
  py: "Параґвай",
  qa: "Катар",
  re: "Реюньйон",
  ro: "Румунія",
  rs: "Сербія",
  ru: "Росія",
  rw: "Руанда",
  sa: "Саудівська Аравія",
  sb: "Соломонові Острови",
  sc: "Сейшельські Острови",
  sd: "Судан",
  se: "Швеція",
  sg: "Сінгапур",
  sh: "Острів Святої Єлени",
  si: "Словенія",
  sj: "Шпіцберген та Ян-Маєн",
  sk: "Словаччина",
  sl: "Сьєрра-Леоне",
  sm: "Сан-Маріно",
  sn: "Сенегал",
  so: "Сомалі",
  sr: "Сурінам",
  ss: "Південний Судан",
  st: "Сан-Томе і Прінсіпі",
  sv: "Сальвадор",
  sx: "Сінт-Мартен",
  sy: "Сирія",
  sz: "Есватіні",
  tc: "Острови Теркс і Кайкос",
  td: "Чад",
  tg: "Того",
  th: "Таїланд",
  tj: "Таджикистан",
  tk: "Токелау",
  tl: "Тімор-Лешті",
  tm: "Туркменістан",
  tn: "Туніс",
  to: "Тонґа",
  tr: "Туреччина",
  tt: "Трінідад і Тобаґо",
  tv: "Тувалу",
  tw: "Тайвань",
  tz: "Танзанія",
  ua: "Україна",
  ug: "Уганда",
  us: "Сполучені Штати",
  uy: "Уруґвай",
  uz: "Узбекистан",
  va: "Ватикан",
  vc: "Сент-Вінсент і Ґренадіни",
  ve: "Венесуела",
  vg: "Британські Віргінські острови",
  vi: "Віргінські острови, США",
  vn: "Вʼєтнам",
  vu: "Вануату",
  wf: "Уолліс і Футуна",
  ws: "Самоа",
  ye: "Ємен",
  yt: "Майотта",
  za: "Південно-Африканська Республіка",
  zm: "Замбія",
  zw: "Зімбабве"
};
export default countryTranslations;
