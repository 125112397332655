const interfaceTranslations = {
  selectedCountryAriaLabel: "Vybraná země",
  noCountrySelected: "Není vybrána žádná země",
  countryListAriaLabel: "Seznam zemí",
  searchPlaceholder: "Vyhledat",
  zeroSearchResults: "Nebyly nalezeny žádné výsledky",
  oneSearchResult: "1 výsledek nalezen",
  multipleSearchResults: "${count} výsledků nalezeno",
  // additional countries (not supported by country-list library)
  ac: "Ascension",
  xk: "Kosovo"
};
export default interfaceTranslations;
