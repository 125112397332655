const header = document.querySelector('.header');
const wrapper = document.querySelector('.wrapper');

const initFixHeader = () => {
  if (header) {
    const headerHeight = header.getBoundingClientRect().height;
    const isMainHeader = header.classList.contains('header--main');
    window.addEventListener('scroll', () => {
      const scrollPosition = window.pageYOffset;
      if (scrollPosition > isMainHeader ? 10 : 0) {
        header.classList.add('header--fixed');
        header.classList.add('active');
        if(!isMainHeader)
          wrapper.style.paddingTop = `${headerHeight}px`;
      } else {
        header.classList.remove('header--fixed');
        header.classList.remove('active');
        if(!isMainHeader)
          wrapper.style.paddingTop = 0;
      }
    });
  }
};

export {initFixHeader};
