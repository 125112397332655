const countryTranslations = {
  ad: "Andorra",
  ae: "Emiratos Árabes Unidos",
  af: "Afganistán",
  ag: "Antigua y Barbuda",
  ai: "Anguila",
  al: "Albania",
  am: "Armenia",
  ao: "Angola",
  ar: "Argentina",
  as: "Samoa Americana",
  at: "Austria",
  au: "Australia",
  aw: "Aruba",
  ax: "Islas Åland",
  az: "Azerbaiyán",
  ba: "Bosnia y Herzegovina",
  bb: "Barbados",
  bd: "Bangladés",
  be: "Bélgica",
  bf: "Burkina Faso",
  bg: "Bulgaria",
  bh: "Baréin",
  bi: "Burundi",
  bj: "Benín",
  bl: "San Bartolomé",
  bm: "Bermudas",
  bn: "Brunéi",
  bo: "Bolivia",
  bq: "Caribe neerlandés",
  br: "Brasil",
  bs: "Bahamas",
  bt: "Bután",
  bw: "Botsuana",
  by: "Bielorrusia",
  bz: "Belice",
  ca: "Canadá",
  cc: "Islas Cocos",
  cd: "República Democrática del Congo",
  cf: "República Centroafricana",
  cg: "Congo",
  ch: "Suiza",
  ci: "Côte d’Ivoire",
  ck: "Islas Cook",
  cl: "Chile",
  cm: "Camerún",
  cn: "China",
  co: "Colombia",
  cr: "Costa Rica",
  cu: "Cuba",
  cv: "Cabo Verde",
  cw: "Curazao",
  cx: "Isla de Navidad",
  cy: "Chipre",
  cz: "Chequia",
  de: "Alemania",
  dj: "Yibuti",
  dk: "Dinamarca",
  dm: "Dominica",
  do: "República Dominicana",
  dz: "Argelia",
  ec: "Ecuador",
  ee: "Estonia",
  eg: "Egipto",
  eh: "Sáhara Occidental",
  er: "Eritrea",
  es: "España",
  et: "Etiopía",
  fi: "Finlandia",
  fj: "Fiyi",
  fk: "Islas Malvinas",
  fm: "Micronesia",
  fo: "Islas Feroe",
  fr: "Francia",
  ga: "Gabón",
  gb: "Reino Unido",
  gd: "Granada",
  ge: "Georgia",
  gf: "Guayana Francesa",
  gg: "Guernsey",
  gh: "Ghana",
  gi: "Gibraltar",
  gl: "Groenlandia",
  gm: "Gambia",
  gn: "Guinea",
  gp: "Guadalupe",
  gq: "Guinea Ecuatorial",
  gr: "Grecia",
  gt: "Guatemala",
  gu: "Guam",
  gw: "Guinea-Bisáu",
  gy: "Guyana",
  hk: "RAE de Hong Kong (China)",
  hn: "Honduras",
  hr: "Croacia",
  ht: "Haití",
  hu: "Hungría",
  id: "Indonesia",
  ie: "Irlanda",
  il: "Israel",
  im: "Isla de Man",
  in: "India",
  io: "Territorio Británico del Océano Índico",
  iq: "Irak",
  ir: "Irán",
  is: "Islandia",
  it: "Italia",
  je: "Jersey",
  jm: "Jamaica",
  jo: "Jordania",
  jp: "Japón",
  ke: "Kenia",
  kg: "Kirguistán",
  kh: "Camboya",
  ki: "Kiribati",
  km: "Comoras",
  kn: "San Cristóbal y Nieves",
  kp: "Corea del Norte",
  kr: "Corea del Sur",
  kw: "Kuwait",
  ky: "Islas Caimán",
  kz: "Kazajistán",
  la: "Laos",
  lb: "Líbano",
  lc: "Santa Lucía",
  li: "Liechtenstein",
  lk: "Sri Lanka",
  lr: "Liberia",
  ls: "Lesoto",
  lt: "Lituania",
  lu: "Luxemburgo",
  lv: "Letonia",
  ly: "Libia",
  ma: "Marruecos",
  mc: "Mónaco",
  md: "Moldavia",
  me: "Montenegro",
  mf: "San Martín",
  mg: "Madagascar",
  mh: "Islas Marshall",
  mk: "Macedonia del Norte",
  ml: "Mali",
  mm: "Myanmar (Birmania)",
  mn: "Mongolia",
  mo: "RAE de Macao (China)",
  mp: "Islas Marianas del Norte",
  mq: "Martinica",
  mr: "Mauritania",
  ms: "Montserrat",
  mt: "Malta",
  mu: "Mauricio",
  mv: "Maldivas",
  mw: "Malaui",
  mx: "México",
  my: "Malasia",
  mz: "Mozambique",
  na: "Namibia",
  nc: "Nueva Caledonia",
  ne: "Níger",
  nf: "Isla Norfolk",
  ng: "Nigeria",
  ni: "Nicaragua",
  nl: "Países Bajos",
  no: "Noruega",
  np: "Nepal",
  nr: "Nauru",
  nu: "Niue",
  nz: "Nueva Zelanda",
  om: "Omán",
  pa: "Panamá",
  pe: "Perú",
  pf: "Polinesia Francesa",
  pg: "Papúa Nueva Guinea",
  ph: "Filipinas",
  pk: "Pakistán",
  pl: "Polonia",
  pm: "San Pedro y Miquelón",
  pr: "Puerto Rico",
  ps: "Territorios Palestinos",
  pt: "Portugal",
  pw: "Palaos",
  py: "Paraguay",
  qa: "Catar",
  re: "Reunión",
  ro: "Rumanía",
  rs: "Serbia",
  ru: "Rusia",
  rw: "Ruanda",
  sa: "Arabia Saudí",
  sb: "Islas Salomón",
  sc: "Seychelles",
  sd: "Sudán",
  se: "Suecia",
  sg: "Singapur",
  sh: "Santa Elena",
  si: "Eslovenia",
  sj: "Svalbard y Jan Mayen",
  sk: "Eslovaquia",
  sl: "Sierra Leona",
  sm: "San Marino",
  sn: "Senegal",
  so: "Somalia",
  sr: "Surinam",
  ss: "Sudán del Sur",
  st: "Santo Tomé y Príncipe",
  sv: "El Salvador",
  sx: "Sint Maarten",
  sy: "Siria",
  sz: "Esuatini",
  tc: "Islas Turcas y Caicos",
  td: "Chad",
  tg: "Togo",
  th: "Tailandia",
  tj: "Tayikistán",
  tk: "Tokelau",
  tl: "Timor-Leste",
  tm: "Turkmenistán",
  tn: "Túnez",
  to: "Tonga",
  tr: "Turquía",
  tt: "Trinidad y Tobago",
  tv: "Tuvalu",
  tw: "Taiwán",
  tz: "Tanzania",
  ua: "Ucrania",
  ug: "Uganda",
  us: "Estados Unidos",
  uy: "Uruguay",
  uz: "Uzbekistán",
  va: "Ciudad del Vaticano",
  vc: "San Vicente y las Granadinas",
  ve: "Venezuela",
  vg: "Islas Vírgenes Británicas",
  vi: "Islas Vírgenes de EE. UU.",
  vn: "Vietnam",
  vu: "Vanuatu",
  wf: "Wallis y Futuna",
  ws: "Samoa",
  ye: "Yemen",
  yt: "Mayotte",
  za: "Sudáfrica",
  zm: "Zambia",
  zw: "Zimbabue"
};
export default countryTranslations;
