const countryTranslations = {
  ad: "ఆండోరా",
  ae: "యునైటెడ్ అరబ్ ఎమిరేట్స్",
  af: "ఆఫ్ఘనిస్తాన్",
  ag: "ఆంటిగ్వా మరియు బార్బుడా",
  ai: "ఆంగ్విల్లా",
  al: "అల్బేనియా",
  am: "ఆర్మేనియా",
  ao: "అంగోలా",
  ar: "అర్జెంటీనా",
  as: "అమెరికన్ సమోవా",
  at: "ఆస్ట్రియా",
  au: "ఆస్ట్రేలియా",
  aw: "అరుబా",
  ax: "ఆలాండ్ దీవులు",
  az: "అజర్బైజాన్",
  ba: "బోస్నియా మరియు హెర్జిగోవినా",
  bb: "బార్బడోస్",
  bd: "బంగ్లాదేశ్",
  be: "బెల్జియం",
  bf: "బుర్కినా ఫాసో",
  bg: "బల్గేరియా",
  bh: "బహ్రెయిన్",
  bi: "బురుండి",
  bj: "బెనిన్",
  bl: "సెయింట్ బర్థెలిమి",
  bm: "బెర్ముడా",
  bn: "బ్రూనే",
  bo: "బొలీవియా",
  bq: "కరీబియన్ నెదర్లాండ్స్",
  br: "బ్రెజిల్",
  bs: "బహామాస్",
  bt: "భూటాన్",
  bw: "బోట్స్వానా",
  by: "బెలారస్",
  bz: "బెలిజ్",
  ca: "కెనడా",
  cc: "కోకోస్ (కీలింగ్) దీవులు",
  cd: "కాంగో- కిన్షాసా",
  cf: "సెంట్రల్ ఆఫ్రికన్ రిపబ్లిక్",
  cg: "కాంగో- బ్రాజావిల్లి",
  ch: "స్విట్జర్లాండ్",
  ci: "కోట్ డి ఐవోర్",
  ck: "కుక్ దీవులు",
  cl: "చిలీ",
  cm: "కామెరూన్",
  cn: "చైనా",
  co: "కొలంబియా",
  cr: "కోస్టా రికా",
  cu: "క్యూబా",
  cv: "కేప్ వెర్డె",
  cw: "క్యూరసో",
  cx: "క్రిస్మస్ దీవి",
  cy: "సైప్రస్",
  cz: "చెకియా",
  de: "జర్మనీ",
  dj: "జిబౌటి",
  dk: "డెన్మార్క్",
  dm: "డొమినికా",
  do: "డొమినికన్ రిపబ్లిక్",
  dz: "అల్జీరియా",
  ec: "ఈక్వడార్",
  ee: "ఎస్టోనియా",
  eg: "ఈజిప్ట్",
  eh: "పడమటి సహారా",
  er: "ఎరిట్రియా",
  es: "స్పెయిన్",
  et: "ఇథియోపియా",
  fi: "ఫిన్లాండ్",
  fj: "ఫిజీ",
  fk: "ఫాక్‌ల్యాండ్ దీవులు",
  fm: "మైక్రోనేషియా",
  fo: "ఫారో దీవులు",
  fr: "ఫ్రాన్స్‌",
  ga: "గేబన్",
  gb: "యునైటెడ్ కింగ్‌డమ్",
  gd: "గ్రెనడా",
  ge: "జార్జియా",
  gf: "ఫ్రెంచ్ గియానా",
  gg: "గర్న్‌సీ",
  gh: "ఘనా",
  gi: "జిబ్రాల్టర్",
  gl: "గ్రీన్‌ల్యాండ్",
  gm: "గాంబియా",
  gn: "గినియా",
  gp: "గ్వాడెలోప్",
  gq: "ఈక్వటోరియల్ గినియా",
  gr: "గ్రీస్",
  gt: "గ్వాటిమాలా",
  gu: "గ్వామ్",
  gw: "గినియా-బిస్సావ్",
  gy: "గయానా",
  hk: "హాంకాంగ్ ఎస్ఏఆర్ చైనా",
  hn: "హోండురాస్",
  hr: "క్రొయేషియా",
  ht: "హైటి",
  hu: "హంగేరీ",
  id: "ఇండోనేషియా",
  ie: "ఐర్లాండ్",
  il: "ఇజ్రాయెల్",
  im: "ఐల్ ఆఫ్ మాన్",
  in: "భారతదేశం",
  io: "బ్రిటిష్ హిందూ మహాసముద్ర ప్రాంతం",
  iq: "ఇరాక్",
  ir: "ఇరాన్",
  is: "ఐస్లాండ్",
  it: "ఇటలీ",
  je: "జెర్సీ",
  jm: "జమైకా",
  jo: "జోర్డాన్",
  jp: "జపాన్",
  ke: "కెన్యా",
  kg: "కిర్గిజిస్తాన్",
  kh: "కంబోడియా",
  ki: "కిరిబాటి",
  km: "కొమొరోస్",
  kn: "సెయింట్ కిట్స్ మరియు నెవిస్",
  kp: "ఉత్తర కొరియా",
  kr: "దక్షిణ కొరియా",
  kw: "కువైట్",
  ky: "కేమాన్ దీవులు",
  kz: "కజకిస్తాన్",
  la: "లావోస్",
  lb: "లెబనాన్",
  lc: "సెయింట్ లూసియా",
  li: "లిక్టెన్‌స్టెయిన్",
  lk: "శ్రీలంక",
  lr: "లైబీరియా",
  ls: "లెసోతో",
  lt: "లిథువేనియా",
  lu: "లక్సెంబర్గ్",
  lv: "లాత్వియా",
  ly: "లిబియా",
  ma: "మొరాకో",
  mc: "మొనాకో",
  md: "మోల్డోవా",
  me: "మాంటెనెగ్రో",
  mf: "సెయింట్ మార్టిన్",
  mg: "మడగాస్కర్",
  mh: "మార్షల్ దీవులు",
  mk: "ఉత్తర మాసిడోనియా",
  ml: "మాలి",
  mm: "మయన్మార్",
  mn: "మంగోలియా",
  mo: "మకావ్ ఎస్ఏఆర్ చైనా",
  mp: "ఉత్తర మరియానా దీవులు",
  mq: "మార్టినీక్",
  mr: "మౌరిటేనియా",
  ms: "మాంట్సెరాట్",
  mt: "మాల్టా",
  mu: "మారిషస్",
  mv: "మాల్దీవులు",
  mw: "మలావీ",
  mx: "మెక్సికో",
  my: "మలేషియా",
  mz: "మొజాంబిక్",
  na: "నమీబియా",
  nc: "క్రొత్త కాలెడోనియా",
  ne: "నైజర్",
  nf: "నార్ఫోక్ దీవి",
  ng: "నైజీరియా",
  ni: "నికరాగువా",
  nl: "నెదర్లాండ్స్",
  no: "నార్వే",
  np: "నేపాల్",
  nr: "నౌరు",
  nu: "నియూ",
  nz: "న్యూజిలాండ్",
  om: "ఓమన్",
  pa: "పనామా",
  pe: "పెరూ",
  pf: "ఫ్రెంచ్ పోలినీషియా",
  pg: "పాపువా న్యూ గినియా",
  ph: "ఫిలిప్పైన్స్",
  pk: "పాకిస్తాన్",
  pl: "పోలాండ్",
  pm: "సెయింట్ పియెర్ మరియు మికెలాన్",
  pr: "ప్యూర్టో రికో",
  ps: "పాలస్తీనియన్ ప్రాంతాలు",
  pt: "పోర్చుగల్",
  pw: "పాలావ్",
  py: "పరాగ్వే",
  qa: "ఖతార్",
  re: "రీయూనియన్",
  ro: "రోమేనియా",
  rs: "సెర్బియా",
  ru: "రష్యా",
  rw: "రువాండా",
  sa: "సౌదీ అరేబియా",
  sb: "సోలమన్ దీవులు",
  sc: "సీషెల్స్",
  sd: "సూడాన్",
  se: "స్వీడన్",
  sg: "సింగపూర్",
  sh: "సెయింట్ హెలెనా",
  si: "స్లోవేనియా",
  sj: "స్వాల్‌బార్డ్ మరియు జాన్ మాయెన్",
  sk: "స్లొవేకియా",
  sl: "సియెర్రా లియాన్",
  sm: "శాన్ మారినో",
  sn: "సెనెగల్",
  so: "సోమాలియా",
  sr: "సూరినామ్",
  ss: "దక్షిణ సూడాన్",
  st: "సావో టోమ్ మరియు ప్రిన్సిపి",
  sv: "ఎల్ సాల్వడోర్",
  sx: "సింట్ మార్టెన్",
  sy: "సిరియా",
  sz: "ఈస్వాటిని",
  tc: "టర్క్స్ మరియు కైకోస్ దీవులు",
  td: "చాద్",
  tg: "టోగో",
  th: "థాయిలాండ్",
  tj: "తజికిస్తాన్",
  tk: "టోకెలావ్",
  tl: "టిమోర్-లెస్టె",
  tm: "టర్క్‌మెనిస్తాన్",
  tn: "ట్యునీషియా",
  to: "టోంగా",
  tr: "టర్కీ",
  tt: "ట్రినిడాడ్ మరియు టొబాగో",
  tv: "టువాలు",
  tw: "తైవాన్",
  tz: "టాంజానియా",
  ua: "ఉక్రెయిన్",
  ug: "ఉగాండా",
  us: "యునైటెడ్ స్టేట్స్",
  uy: "ఉరుగ్వే",
  uz: "ఉజ్బెకిస్తాన్",
  va: "వాటికన్ నగరం",
  vc: "సెయింట్ విన్సెంట్ మరియు గ్రెనడీన్స్",
  ve: "వెనిజులా",
  vg: "బ్రిటిష్ వర్జిన్ దీవులు",
  vi: "యు.ఎస్. వర్జిన్ దీవులు",
  vn: "వియత్నాం",
  vu: "వనాటు",
  wf: "వాల్లిస్ మరియు ఫుటునా",
  ws: "సమోవా",
  ye: "యెమెన్",
  yt: "మాయొట్",
  za: "దక్షిణ ఆఫ్రికా",
  zm: "జాంబియా",
  zw: "జింబాబ్వే"
};
export default countryTranslations;
