const countryTranslations = {
  ad: "Andorra",
  ae: "Birleşik Arap Emirlikleri",
  af: "Afganistan",
  ag: "Antigua ve Barbuda",
  ai: "Anguilla",
  al: "Arnavutluk",
  am: "Ermenistan",
  ao: "Angola",
  ar: "Arjantin",
  as: "Amerikan Samoası",
  at: "Avusturya",
  au: "Avustralya",
  aw: "Aruba",
  ax: "Åland Adaları",
  az: "Azerbaycan",
  ba: "Bosna-Hersek",
  bb: "Barbados",
  bd: "Bangladeş",
  be: "Belçika",
  bf: "Burkina Faso",
  bg: "Bulgaristan",
  bh: "Bahreyn",
  bi: "Burundi",
  bj: "Benin",
  bl: "Saint Barthelemy",
  bm: "Bermuda",
  bn: "Brunei",
  bo: "Bolivya",
  bq: "Karayip Hollandası",
  br: "Brezilya",
  bs: "Bahamalar",
  bt: "Butan",
  bw: "Botsvana",
  by: "Belarus",
  bz: "Belize",
  ca: "Kanada",
  cc: "Cocos (Keeling) Adaları",
  cd: "Kongo - Kinşasa",
  cf: "Orta Afrika Cumhuriyeti",
  cg: "Kongo - Brazavil",
  ch: "İsviçre",
  ci: "Côte d’Ivoire",
  ck: "Cook Adaları",
  cl: "Şili",
  cm: "Kamerun",
  cn: "Çin",
  co: "Kolombiya",
  cr: "Kosta Rika",
  cu: "Küba",
  cv: "Cape Verde",
  cw: "Curaçao",
  cx: "Christmas Adası",
  cy: "Kıbrıs",
  cz: "Çekya",
  de: "Almanya",
  dj: "Cibuti",
  dk: "Danimarka",
  dm: "Dominika",
  do: "Dominik Cumhuriyeti",
  dz: "Cezayir",
  ec: "Ekvador",
  ee: "Estonya",
  eg: "Mısır",
  eh: "Batı Sahra",
  er: "Eritre",
  es: "İspanya",
  et: "Etiyopya",
  fi: "Finlandiya",
  fj: "Fiji",
  fk: "Falkland Adaları",
  fm: "Mikronezya",
  fo: "Faroe Adaları",
  fr: "Fransa",
  ga: "Gabon",
  gb: "Birleşik Krallık",
  gd: "Grenada",
  ge: "Gürcistan",
  gf: "Fransız Guyanası",
  gg: "Guernsey",
  gh: "Gana",
  gi: "Cebelitarık",
  gl: "Grönland",
  gm: "Gambiya",
  gn: "Gine",
  gp: "Guadeloupe",
  gq: "Ekvator Ginesi",
  gr: "Yunanistan",
  gt: "Guatemala",
  gu: "Guam",
  gw: "Gine-Bissau",
  gy: "Guyana",
  hk: "Çin Hong Kong ÖİB",
  hn: "Honduras",
  hr: "Hırvatistan",
  ht: "Haiti",
  hu: "Macaristan",
  id: "Endonezya",
  ie: "İrlanda",
  il: "İsrail",
  im: "Man Adası",
  in: "Hindistan",
  io: "Britanya Hint Okyanusu Toprakları",
  iq: "Irak",
  ir: "İran",
  is: "İzlanda",
  it: "İtalya",
  je: "Jersey",
  jm: "Jamaika",
  jo: "Ürdün",
  jp: "Japonya",
  ke: "Kenya",
  kg: "Kırgızistan",
  kh: "Kamboçya",
  ki: "Kiribati",
  km: "Komorlar",
  kn: "Saint Kitts ve Nevis",
  kp: "Kuzey Kore",
  kr: "Güney Kore",
  kw: "Kuveyt",
  ky: "Cayman Adaları",
  kz: "Kazakistan",
  la: "Laos",
  lb: "Lübnan",
  lc: "Saint Lucia",
  li: "Liechtenstein",
  lk: "Sri Lanka",
  lr: "Liberya",
  ls: "Lesotho",
  lt: "Litvanya",
  lu: "Lüksemburg",
  lv: "Letonya",
  ly: "Libya",
  ma: "Fas",
  mc: "Monako",
  md: "Moldova",
  me: "Karadağ",
  mf: "Saint Martin",
  mg: "Madagaskar",
  mh: "Marshall Adaları",
  mk: "Kuzey Makedonya",
  ml: "Mali",
  mm: "Myanmar (Burma)",
  mn: "Moğolistan",
  mo: "Çin Makao ÖİB",
  mp: "Kuzey Mariana Adaları",
  mq: "Martinik",
  mr: "Moritanya",
  ms: "Montserrat",
  mt: "Malta",
  mu: "Mauritius",
  mv: "Maldivler",
  mw: "Malavi",
  mx: "Meksika",
  my: "Malezya",
  mz: "Mozambik",
  na: "Namibya",
  nc: "Yeni Kaledonya",
  ne: "Nijer",
  nf: "Norfolk Adası",
  ng: "Nijerya",
  ni: "Nikaragua",
  nl: "Hollanda",
  no: "Norveç",
  np: "Nepal",
  nr: "Nauru",
  nu: "Niue",
  nz: "Yeni Zelanda",
  om: "Umman",
  pa: "Panama",
  pe: "Peru",
  pf: "Fransız Polinezyası",
  pg: "Papua Yeni Gine",
  ph: "Filipinler",
  pk: "Pakistan",
  pl: "Polonya",
  pm: "Saint Pierre ve Miquelon",
  pr: "Porto Riko",
  ps: "Filistin Bölgeleri",
  pt: "Portekiz",
  pw: "Palau",
  py: "Paraguay",
  qa: "Katar",
  re: "Reunion",
  ro: "Romanya",
  rs: "Sırbistan",
  ru: "Rusya",
  rw: "Ruanda",
  sa: "Suudi Arabistan",
  sb: "Solomon Adaları",
  sc: "Seyşeller",
  sd: "Sudan",
  se: "İsveç",
  sg: "Singapur",
  sh: "Saint Helena",
  si: "Slovenya",
  sj: "Svalbard ve Jan Mayen",
  sk: "Slovakya",
  sl: "Sierra Leone",
  sm: "San Marino",
  sn: "Senegal",
  so: "Somali",
  sr: "Surinam",
  ss: "Güney Sudan",
  st: "Sao Tome ve Principe",
  sv: "El Salvador",
  sx: "Sint Maarten",
  sy: "Suriye",
  sz: "Esvatini",
  tc: "Turks ve Caicos Adaları",
  td: "Çad",
  tg: "Togo",
  th: "Tayland",
  tj: "Tacikistan",
  tk: "Tokelau",
  tl: "Timor-Leste",
  tm: "Türkmenistan",
  tn: "Tunus",
  to: "Tonga",
  tr: "Türkiye",
  tt: "Trinidad ve Tobago",
  tv: "Tuvalu",
  tw: "Tayvan",
  tz: "Tanzanya",
  ua: "Ukrayna",
  ug: "Uganda",
  us: "Amerika Birleşik Devletleri",
  uy: "Uruguay",
  uz: "Özbekistan",
  va: "Vatikan",
  vc: "Saint Vincent ve Grenadinler",
  ve: "Venezuela",
  vg: "Britanya Virjin Adaları",
  vi: "ABD Virjin Adaları",
  vn: "Vietnam",
  vu: "Vanuatu",
  wf: "Wallis ve Futuna",
  ws: "Samoa",
  ye: "Yemen",
  yt: "Mayotte",
  za: "Güney Afrika",
  zm: "Zambiya",
  zw: "Zimbabve"
};
export default countryTranslations;
