const countryTranslations = {
  ad: "Andora",
  ae: "Ujedinjeni Arapski Emirati",
  af: "Afganistan",
  ag: "Antigva i Barbuda",
  ai: "Angvila",
  al: "Albanija",
  am: "Armenija",
  ao: "Angola",
  ar: "Argentina",
  as: "Američka Samoa",
  at: "Austrija",
  au: "Australija",
  aw: "Aruba",
  ax: "Olandska ostrva",
  az: "Azerbejdžan",
  ba: "Bosna i Hercegovina",
  bb: "Barbados",
  bd: "Bangladeš",
  be: "Belgija",
  bf: "Burkina Faso",
  bg: "Bugarska",
  bh: "Bahrein",
  bi: "Burundi",
  bj: "Benin",
  bl: "Sveti Bartolomej",
  bm: "Bermuda",
  bn: "Brunej",
  bo: "Bolivija",
  bq: "Karipska Holandija",
  br: "Brazil",
  bs: "Bahami",
  bt: "Butan",
  bw: "Bocvana",
  by: "Bjelorusija",
  bz: "Belize",
  ca: "Kanada",
  cc: "Kokosova (Keelingova) ostrva",
  cd: "Demokratska Republika Kongo",
  cf: "Centralnoafrička Republika",
  cg: "Kongo",
  ch: "Švicarska",
  ci: "Obala Slonovače",
  ck: "Kukova ostrva",
  cl: "Čile",
  cm: "Kamerun",
  cn: "Kina",
  co: "Kolumbija",
  cr: "Kostarika",
  cu: "Kuba",
  cv: "Kape Verde",
  cw: "Kurasao",
  cx: "Božićno ostrvo",
  cy: "Kipar",
  cz: "Češka",
  de: "Njemačka",
  dj: "Džibuti",
  dk: "Danska",
  dm: "Dominika",
  do: "Dominikanska Republika",
  dz: "Alžir",
  ec: "Ekvador",
  ee: "Estonija",
  eg: "Egipat",
  eh: "Zapadna Sahara",
  er: "Eritreja",
  es: "Španija",
  et: "Etiopija",
  fi: "Finska",
  fj: "Fidži",
  fk: "Folklandska ostrva",
  fm: "Mikronezija",
  fo: "Farska ostrva",
  fr: "Francuska",
  ga: "Gabon",
  gb: "Ujedinjeno Kraljevstvo",
  gd: "Grenada",
  ge: "Gruzija",
  gf: "Francuska Gvajana",
  gg: "Gernzi",
  gh: "Gana",
  gi: "Gibraltar",
  gl: "Grenland",
  gm: "Gambija",
  gn: "Gvineja",
  gp: "Gvadalupe",
  gq: "Ekvatorijalna Gvineja",
  gr: "Grčka",
  gt: "Gvatemala",
  gu: "Guam",
  gw: "Gvineja-Bisao",
  gy: "Gvajana",
  hk: "Hong Kong (SAR Kina)",
  hn: "Honduras",
  hr: "Hrvatska",
  ht: "Haiti",
  hu: "Mađarska",
  id: "Indonezija",
  ie: "Irska",
  il: "Izrael",
  im: "Ostrvo Man",
  in: "Indija",
  io: "Britanska Teritorija u Indijskom Okeanu",
  iq: "Irak",
  ir: "Iran",
  is: "Island",
  it: "Italija",
  je: "Jersey",
  jm: "Jamajka",
  jo: "Jordan",
  jp: "Japan",
  ke: "Kenija",
  kg: "Kirgistan",
  kh: "Kambodža",
  ki: "Kiribati",
  km: "Komori",
  kn: "Sveti Kits i Nevis",
  kp: "Sjeverna Koreja",
  kr: "Južna Koreja",
  kw: "Kuvajt",
  ky: "Kajmanska ostrva",
  kz: "Kazahstan",
  la: "Laos",
  lb: "Liban",
  lc: "Sveta Lucija",
  li: "Lihtenštajn",
  lk: "Šri Lanka",
  lr: "Liberija",
  ls: "Lesoto",
  lt: "Litvanija",
  lu: "Luksemburg",
  lv: "Latvija",
  ly: "Libija",
  ma: "Maroko",
  mc: "Monako",
  md: "Moldavija",
  me: "Crna Gora",
  mf: "Sveti Martin",
  mg: "Madagaskar",
  mh: "Maršalova ostrva",
  mk: "Sjeverna Makedonija",
  ml: "Mali",
  mm: "Mjanmar",
  mn: "Mongolija",
  mo: "Makao (SAR Kina)",
  mp: "Sjeverna Marijanska ostrva",
  mq: "Martinik",
  mr: "Mauritanija",
  ms: "Monserat",
  mt: "Malta",
  mu: "Mauricijus",
  mv: "Maldivi",
  mw: "Malavi",
  mx: "Meksiko",
  my: "Malezija",
  mz: "Mozambik",
  na: "Namibija",
  nc: "Nova Kaledonija",
  ne: "Niger",
  nf: "Ostrvo Norfolk",
  ng: "Nigerija",
  ni: "Nikaragva",
  nl: "Holandija",
  no: "Norveška",
  np: "Nepal",
  nr: "Nauru",
  nu: "Niue",
  nz: "Novi Zeland",
  om: "Oman",
  pa: "Panama",
  pe: "Peru",
  pf: "Francuska Polinezija",
  pg: "Papua Nova Gvineja",
  ph: "Filipini",
  pk: "Pakistan",
  pl: "Poljska",
  pm: "Sveti Petar i Mikelon",
  pr: "Porto Riko",
  ps: "Palestinska Teritorija",
  pt: "Portugal",
  pw: "Palau",
  py: "Paragvaj",
  qa: "Katar",
  re: "Reunion",
  ro: "Rumunija",
  rs: "Srbija",
  ru: "Rusija",
  rw: "Ruanda",
  sa: "Saudijska Arabija",
  sb: "Solomonska Ostrva",
  sc: "Sejšeli",
  sd: "Sudan",
  se: "Švedska",
  sg: "Singapur",
  sh: "Sveta Helena",
  si: "Slovenija",
  sj: "Svalbard i Jan Majen",
  sk: "Slovačka",
  sl: "Sijera Leone",
  sm: "San Marino",
  sn: "Senegal",
  so: "Somalija",
  sr: "Surinam",
  ss: "Južni Sudan",
  st: "Sao Tome i Principe",
  sv: "Salvador",
  sx: "Sint Marten",
  sy: "Sirija",
  sz: "Esvatini",
  tc: "Ostrva Turks i Kaikos",
  td: "Čad",
  tg: "Togo",
  th: "Tajland",
  tj: "Tadžikistan",
  tk: "Tokelau",
  tl: "Istočni Timor",
  tm: "Turkmenistan",
  tn: "Tunis",
  to: "Tonga",
  tr: "Turska",
  tt: "Trinidad i Tobago",
  tv: "Tuvalu",
  tw: "Tajvan",
  tz: "Tanzanija",
  ua: "Ukrajina",
  ug: "Uganda",
  us: "Sjedinjene Države",
  uy: "Urugvaj",
  uz: "Uzbekistan",
  va: "Vatikan",
  vc: "Sveti Vinsent i Grenadin",
  ve: "Venecuela",
  vg: "Britanska Djevičanska ostrva",
  vi: "Američka Djevičanska ostrva",
  vn: "Vijetnam",
  vu: "Vanuatu",
  wf: "Ostrva Valis i Futuna",
  ws: "Samoa",
  ye: "Jemen",
  yt: "Majote",
  za: "Južnoafrička Republika",
  zm: "Zambija",
  zw: "Zimbabve"
};
export default countryTranslations;
