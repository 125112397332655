const form = document.querySelector('.submit-documents__form--tax'),
  recipientBlock = document.getElementById('recipient_block'),
  recipientPassport = document.getElementById('recipient_passport'),
  recipientCertificate = document.getElementById('recipient_certificate'),
  recipientInputs = recipientBlock?.querySelectorAll('input'),
  recipientInputsRequired = recipientBlock?.firstElementChild?.querySelectorAll('input'),
  inputType = form?.querySelector('input[name=type]'),
  radioMethod = form?.querySelectorAll('input[name=method]'),
  clinicBlock = form?.querySelector('.js-tax-input-clinic'),
  addressBlock = form?.querySelector('.js-tax-input-address'),
  addressTextarea = addressBlock?.querySelector('textarea');

const resetBlocks = () => {
  recipientBlock.style.display = 'none';
  recipientPassport.style.display = 'none';
  recipientCertificate.style.display = 'none';
};

const resetRecipientInputs = () => {
  if (!recipientInputs)
    return;

  recipientInputs.forEach(input => {
    input.value = '';
    input.removeAttribute('required');
  });
};

const setRecipientInputsRequired = () => {
  if (!recipientInputsRequired)
    return;

  recipientInputsRequired.forEach(input => {
    const required = input.dataset?.required;
    if (!required || required !== 'false')
      input.setAttribute('required', 'required');
  });
};

const showAddressBlock = () => {
  addressTextarea.value = '';
  addressTextarea.setAttribute('required', 'required');
  addressBlock.classList.remove('has-placeholder-hidden');
  addressBlock.style.display = 'block';
  clinicBlock.style.display = '';
};

const showClinicBlock = () => {
  addressTextarea.value = '';
  addressTextarea.removeAttribute('required');
  addressBlock.style.display = '';
  clinicBlock.style.display = 'block';
};

const hideBlocks = () => {
  addressTextarea.value = '';
  addressTextarea.removeAttribute('required');
  addressBlock.style.display = '';
  clinicBlock.style.display = '';
};

const changeTaxSelectType = (element) => {
  const variant = +element.dataset.value;

  if (!form || !variant)
    return;

  resetBlocks();
  resetRecipientInputs();

  switch (variant) {
    case 1:
      break;
    case 4:
      setRecipientInputsRequired();
      recipientCertificate.style.display = 'flex';
      recipientBlock.style.display = 'block';
      break;
    default:
      setRecipientInputsRequired();
      recipientPassport.style.display = 'flex';
      recipientBlock.style.display = 'block';
  }

  if (inputType)
    inputType.value = variant;
};

const initTaxForm = () => {
  if (!form)
    return;

  radioMethod.forEach((input) => {
    input.addEventListener('change', (e) => {
      switch (input.value) {
        case 'clinic':
          showClinicBlock();
          break;
        case 'mail':
          showAddressBlock();
          break;
        case 'ifns':
          hideBlocks();
          break;
      }
    });
  });
};

export {changeTaxSelectType, initTaxForm};
