const video = document.querySelector('.doctor-card__video-block video');
const controlBlock = document.querySelector('.doctor-card__video-control');
const volumeButton = document.querySelector('.doctor-card__video-volume');
const timerBlock = document.querySelector('.doctor-card__video-timer');

Number.prototype.lead0 = function (n) {
  let nz = '' + this;
  while (nz.length < n) {
    nz = '0' + nz;
  }
  return nz;
};

const formatTime = (time, hours) => {
  if (hours) {
    let h = Math.floor(time / 3600);
    time = time - h * 3600;

    let m = Math.floor(time / 60);
    let s = Math.floor(time % 60);

    return h.lead0(2) + ':' + m.lead0(2) + ':' + s.lead0(2);
  } else {
    let m = Math.floor(time / 60);
    let s = Math.floor(time % 60);

    return m.lead0(2) + ':' + s.lead0(2);
  }
};

const toggleVolume = (video) => {
  if (video.muted) {
    video.currentTime = 0;
    volumeButton.classList.remove('doctor-card__video-volume--muted');
  } else {
    volumeButton.classList.add('doctor-card__video-volume--muted');
  }

  video.muted = !video.muted;
};

const initDoctorVideo = () => {
  if (!video)
    return;

  const videoParam = {
    duration: 0,
    hasHours: false,
    volume: +video.dataset.volume || 1
  };

  video.addEventListener('loadeddata', function () {
    videoParam.duration = video.duration;
    videoParam.hasHours = (video.duration / 3600) >= 1.0;
    video.volume = videoParam.volume;
  });

  video.addEventListener('timeupdate', function () {
    if (!controlBlock.classList.contains('doctor-card__video-control--visible'))
      controlBlock.classList.add('doctor-card__video-control--visible');

    timerBlock.innerHTML = formatTime(video.duration - video.currentTime, videoParam.hasHours);
  });

  video.addEventListener('click', function () {
    video.paused ? video.play() : video.pause();
  });

  video.addEventListener('ended', function () {
    video.currentTime = 0;
  });

  volumeButton.addEventListener('click', (event) => {
    event.preventDefault();
    toggleVolume(video);
  });
};

export default initDoctorVideo;
